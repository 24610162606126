import React, { useState } from "react";

import { useFormik } from "formik";
import * as Yup from "yup";

// Components
import Button from "components/Button";

// helpers
import localizer, { capitalize } from "localization/localizer";
import { RequestResetPassword, ResetPassword } from "api/calls";
import md5 from "md5";
import { useNavigate } from "react-router-dom";

// Styling
import styled from "styled-components";
import Page from "./Page";

const EmailSchema = Yup.object().shape({
  email: Yup.string()
    .email(localizer.invalidEmail)
    .required(localizer.required),
  emailConfirm: Yup.string().oneOf(
    [Yup.ref("email"), null],
    localizer.emailMustMatch
  ),
});

const NumberSchema = Yup.object().shape({
  number: Yup.string()
    .min(6, localizer.tooShort)
    .max(6, localizer.tooLong)
    .required(localizer.required),
});

const PasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required(localizer.required)
    .min(6, localizer.passwordTooShort),
  passwordConfirm: Yup.string().oneOf(
    [Yup.ref("password"), null],
    localizer.passwordMustMatch
  ),
});

const Title = styled.div`
  font-size: 22px;
  font-weight: 500;
  text-align: center;
  margin-top: 25px;
`;

const PageWrapper = styled.div`
  background: white;
  width: auto;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 200px;
  overflow: auto;
  border-radius: 5px;
  border: 1px solid gray;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
`;

const StyledForm = styled.form`
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 100%;
  min-width: 700px;
  padding-left: 6em;
  padding-right: 6em;
`;

const FieldWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-flow: row;
  margin-top: 15px;
  width: 100%;
`;

const FieldLabel = styled.label`
  font-size: 18px;
  width: 50%;
  margin-right: 10px;
`;

const FieldValue = styled.input`
  outline: none;
  border: 0.15em solid ${(props) => props.theme.primaryColor};
  border-radius: 15px;
  user-select: none;
  padding: 5px;
  background-color: white;
  height: 35px;
  width: 50%;
`;

const FieldError = styled.div`
  color: red;
  width: 40%;
  margin-top: 2px;
  margin-left: 55%;
`;

const FieldSuccess = styled.div`
  color: green;
  width: 40%;
  margin-top: 2px;
  margin-left: 55%;
`;

const SubmitButton = styled(Button)`
  height: 2.5em;
  margin-top: 25px;
  margin-bottom: 15px;
  width: 40%;
  margin-left: auto;
  background-color: ${(props) => props.theme[props.buttonColor]};
`;

const Divider = styled.div`
  height: 1px;
  background-color: black;
  width: 80%;
  margin-bottom: 15px;
  margin-top: 15px;
  margin-left: auto;
  margin-right: auto;
`;

const ExtraInfo = styled.div``;

const PasswordResetPage = () => {
  const [resetPhase, setResetPhase] = useState(1);
  const [requiredCode, setRequiredCode] = useState();

  const [genericError, setGenericError] = useState(false);
  const [success, setSuccess] = useState(false);

  const [emailUsed, setEmailUsed] = useState();

  const navigate = useNavigate();

  const formikEmail = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: EmailSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      const data = {
        Email: values.email,
      };

      setEmailUsed(values.email);

      RequestResetPassword(data).then((result) => {
        if (result) {
          const code = result?.data?.code || "";

          setRequiredCode(code);
          setResetPhase(2);
        }
      });
    },
  });

  const formikNumber = useFormik({
    initialValues: {
      number: "",
    },
    validationSchema: NumberSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      const newHashed = md5(Number(values.number));

      if (newHashed === requiredCode) {
        setResetPhase(3);
      } else {
        setGenericError(true);
      }
    },
  });

  const formikPass = useFormik({
    initialValues: {
      password: "",
    },
    validationSchema: PasswordSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      if (values.password) {
        const data = {
          Email: emailUsed,
          Pass: md5(values.password),
        };

        ResetPassword(data).then((result) => {
          if (result) {
            setSuccess(true);
            setTimeout(() => {
              navigate("/login");
            }, 1500);
          }
        });
      }
    },
  });

  let content;
  switch (resetPhase) {
    default:
    case 1:
      content = (
        <StyledForm onSubmit={formikEmail.handleSubmit}>
          <FieldWrapper>
            <FieldLabel htmlFor="email">
              {capitalize(localizer.emailAddress)}
            </FieldLabel>
            <FieldValue
              id="email"
              name="email"
              type="email"
              onChange={formikEmail.handleChange}
              value={formikEmail.values.email}
            />
          </FieldWrapper>
          <FieldError>{formikEmail.errors.email}</FieldError>
          <FieldWrapper>
            <FieldLabel htmlFor="emailConfirm">{`${localizer.confirm} ${localizer.emailAddress}`}</FieldLabel>
            <FieldValue
              id="emailConfirm"
              name="emailConfirm"
              type="email"
              onChange={formikEmail.handleChange}
              value={formikEmail.values.emailConfirm}
            />
          </FieldWrapper>
          <FieldError>{formikEmail.errors.emailConfirm}</FieldError>
          <SubmitButton type="submit" text={localizer.confirm} enabled={true} />
        </StyledForm>
      );
      break;
    case 2:
      content = (
        <>
          <ExtraInfo>{localizer.codeInformation}</ExtraInfo>
          <ExtraInfo>{localizer.codeInformation2}</ExtraInfo>
          <StyledForm onSubmit={formikNumber.handleSubmit}>
            <FieldWrapper>
              <FieldLabel htmlFor="number">{`${localizer.code}`}</FieldLabel>
              <FieldValue
                id="number"
                name="number"
                onChange={formikNumber.handleChange}
                value={formikNumber.values.number}
              />
            </FieldWrapper>
            <FieldError>{formikNumber.errors.number}</FieldError>
            {genericError && <FieldError>{localizer.codeInvalid}</FieldError>}
            <SubmitButton
              type="submit"
              text={localizer.confirm}
              enabled={true}
            />
          </StyledForm>
        </>
      );
      break;
    case 3:
      content = (
        <StyledForm onSubmit={formikPass.handleSubmit}>
          <FieldWrapper>
            <FieldLabel htmlFor="password">
              {capitalize(localizer.password)}
            </FieldLabel>
            <FieldValue
              id="password"
              name="password"
              type="password"
              onChange={formikPass.handleChange}
            />
          </FieldWrapper>
          <FieldError>{formikPass.errors.password}</FieldError>
          <FieldWrapper>
            <FieldLabel htmlFor="passwordConfirm">{`${localizer.confirm} ${localizer.password}`}</FieldLabel>
            <FieldValue
              id="passwordConfirm"
              name="passwordConfirm"
              type="password"
              onChange={formikPass.handleChange}
            />
          </FieldWrapper>
          <FieldError>{formikPass.errors.passwordConfirm}</FieldError>
          {success && (
            <FieldSuccess>{localizer.passwordResetSuccess}</FieldSuccess>
          )}
          <SubmitButton type="submit" text={localizer.confirm} enabled={true} />
        </StyledForm>
      );
      break;
  }

  return (
    <Page>
      <PageWrapper>
        <Title>{localizer.passwordReset}</Title>
        <Divider />
        {content}
      </PageWrapper>
    </Page>
  );
};

export default PasswordResetPage;
