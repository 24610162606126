import React, { useState, useEffect } from "react";

// helpers
import { motion, AnimatePresence } from "framer-motion";

import { useAuthState } from "stores/AuthStore";

import { GetPaymentInfo } from "api/calls";

// Styling
import styled from "styled-components";

const MainCard = styled.div`
  display: flex;
  flex-flow: column;
  border: 1px solid black;
  border-radius: 2px;
  margin-bottom: 15px;
  margin-right: 15px;
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  background-color: ${(props) =>
    props.isAdminAdded ? "#00ffffaa" : "#f5f8fa"};
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-flow: row;
  padding: 10px;
`;

const FormWrapper = styled(motion.div)`
  display: flex;
  flex-flow: column;
  justify-content: center;
  background-color: #f5f8fa;
  cursor: default;
`;

const InfoText = styled.div`
  flex-basis: 25%;
`;

const ExtraInfo = styled.div`
  font-size: 1rem;
  margin: 5px;
`;

const Divider = styled(motion.div)`
  height: 1px;
  background-color: black;
`;

const HorizontalWrapper = styled.div`
  display: flex;
  flex-flow: row;
  width: 100%;
  height: 100%;
`;

const BasicInfo = styled.div`
  flex-basis: 50%;
`;

const PaymentInfo = styled.div`
  flex-basis: 50%;
`;

const StandhouderCard = ({ standhouder, standhouders, onClick }) => {
  const { AuthState } = useAuthState();
  const { token } = AuthState;

  const [isOpen, setIsOpen] = useState(false);
  const [orderData, setOrderData] = useState();

  const addedByAdmin = standhouder.Orderid === "0";

  useEffect(() => {
    if (isOpen && !addedByAdmin) {
      GetPaymentInfo(standhouder.Orderid, token).then((result) => {
        if (result.data) {
          setOrderData(result.data[0]);
        }
      });
    }
  }, [isOpen, addedByAdmin, standhouder, token]);

  const parseDate = (date) => {
    if (!date) return "";

    const T = date.indexOf("T");
    const datum = date.substring(0, T);
    const uur = date.substring(T + 1, date.length - 5);

    return datum + " - " + uur;
  };

  return (
    <MainCard
      isAdminAdded={addedByAdmin}
      onClick={() => {
        setIsOpen(!isOpen);
        onClick();
      }}
    >
      <InfoWrapper>
        <InfoText>{standhouder.Standplaats}</InfoText>
        <InfoText>{standhouder.Straat}</InfoText>
        <InfoText>{standhouder.Voornaam}</InfoText>
        <InfoText>{standhouder.Naam}</InfoText>
      </InfoWrapper>
      {isOpen && (
        <AnimatePresence>
          <FormWrapper
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: "auto", opacity: 1 }}
            transition={{ duration: 0.1 }}
            exit={{ height: 0 }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Divider
              initial={{ width: 0 }}
              animate={{ width: "100%" }}
              transition={{ duration: 0.3 }}
              exit={{ width: 0 }}
            />
            <HorizontalWrapper>
              <BasicInfo>
                <ExtraInfo>
                  StandhouderId: {standhouder.StandhouderID}
                </ExtraInfo>
                <ExtraInfo>Email: {standhouder.Email}</ExtraInfo>
                <ExtraInfo>GSM: {standhouder.GSM}</ExtraInfo>
                <ExtraInfo>
                  Standplaatsen:{" "}
                  {standhouders
                    .filter(
                      (x) => x.StandhouderID === standhouder.StandhouderID
                    )
                    .map((x) => (
                      <b>{x.Standplaats + " "}</b>
                    ))}
                </ExtraInfo>
              </BasicInfo>
              <BasicInfo>
                <ExtraInfo>Straat: {standhouder.Straat}</ExtraInfo>
                <ExtraInfo>Huisnummer: {standhouder.Huisnummer}</ExtraInfo>
                <ExtraInfo>Postcode: {standhouder.Postcode}</ExtraInfo>
                <ExtraInfo>Gemeente: {standhouder.Gemeente}</ExtraInfo>
              </BasicInfo>
              {!addedByAdmin && (
                <PaymentInfo>
                  <ExtraInfo>
                    Datum betaling: {parseDate(orderData?.EindBetaling)}
                  </ExtraInfo>
                  <ExtraInfo>Orderid: {orderData?.Orderid}</ExtraInfo>
                  <ExtraInfo>
                    Bedrag: <b>{orderData?.Bedrag}</b>
                  </ExtraInfo>
                  <ExtraInfo>
                    Betaald: <b>{orderData?.Betaald ? "Ja" : "Nee"}</b>
                  </ExtraInfo>
                </PaymentInfo>
              )}
            </HorizontalWrapper>
          </FormWrapper>
        </AnimatePresence>
      )}
    </MainCard>
  );
};

export default StandhouderCard;
