import React from "react";

// Components
import CardSlider from "./CardSlider";

import DrFred from "resources/zaterdag/DrFred.png";
import Ilsen from "resources/zaterdag/ilsen_verhulst.png";
import DefDames from "resources/zaterdag/DefDames.png";
import DjFrank from "resources/zaterdag/DjFrank.png";

import { useNavigate } from "react-router-dom";

import styled from "styled-components";

const Title = styled.h3`
  text-align: center;
`;

const SaturdayPage = () => {
  const navigate = useNavigate();

  const saturdayCards = [
    {
      title: "20u - Dr.Fred\n & the Medicine Men",
      image: DrFred,
      twoLines: true,
    },
    {
      title: "21u15 - Kobe Ilsen\n & Viktor Verhulst",
      image: Ilsen,
      twoLines: true,
    },
    {
      title: "23u - Def Dames Dope",
      image: DefDames,
    },
    {
      title: "24u - DJ F.R.A.N.K",
      image: DjFrank,
    },
  ];

  return (
    <>
      <Title>Zaterdag 30/09</Title>
      <CardSlider
        key="saturday"
        cards={saturdayCards}
        onBackToOverview={() => {
          navigate("..");
        }}
        onRedirect={() => {}}
      />
    </>
  );
};

export default SaturdayPage;
