import React, { useState, useCallback, useEffect, useRef } from "react";

import { Polygon, OverlayView, InfoWindow } from "@react-google-maps/api";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import styled from "styled-components";

const Text = styled.div`
  font-size: 18px;
  font-weight: 500;
  /* transform: ${(props) =>
    `rotate(${props.angle}deg) translate(${props.x}px, ${props.y}px)`}; */
  transform: ${(props) => `translate(${props.x}px, 0px)`};
`;

const NumberInput = styled.input`
  width: 75%;
`;

const Background = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ToolTipWrapper = styled.div`
  position: absolute;
  left: 50px;
  border: 1px solid black;
`;

const ToolTipContent = styled.div`
  display: flex;
  flex-flow: row;
  background-color: white;
  padding: 5px;
`;

const RotateOption = styled.div`
  margin-right: 5px;
  cursor: pointer;
`;

const DeleteOption = styled.div`
  margin-left: 25px;
  cursor: pointer;
`;

const AdminStandplaatsMarker = ({
  plaats,
  onUpdatePlaats,
  canMove,
  canEdit,
  zoomLevel,
  map,
}) => {
  const { Standplaats, path, angle } = plaats;

  const [isDragging, setIsDragging] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [polyInstance, setPolyInstance] = useState();

  const [newPath, setNewPath] = useState([]);
  const [pathToUse, setPathToUse] = useState([]);

  const editListener = useRef();

  const rotatePolygon = useCallback((path, map, a) => {
    const or = new window.google.maps.LatLng(path[0].lat, path[0].lng);
    var prj = map.getProjection();
    var origin = prj.fromLatLngToPoint(or); //rotate around first point

    var coords = path.map((latLng) => {
      const p = new window.google.maps.LatLng(latLng.lat, latLng.lng);
      var point = prj.fromLatLngToPoint(p);
      var rotatedLatLng = prj.fromPointToLatLng(rotatePoint(point, origin, a));
      return { lat: rotatedLatLng.lat(), lng: rotatedLatLng.lng() };
    });

    return coords;
  }, []);

  const onUpdateShape = useCallback(
    (path, bypass) => {
      if (!isDragging || bypass) {
        const newP = [];
        path.forEach((p) => {
          newP.push({
            lat: p.lat(),
            lng: p.lng(),
          });
        });
        const rotated = rotatePolygon(newP, map, -angle);
        onUpdatePlaats({ ...plaats, path: JSON.stringify(rotated) });
      }
    },
    [isDragging, onUpdatePlaats, plaats, map, angle, rotatePolygon]
  );

  useEffect(() => {
    if (canEdit && polyInstance) {
      polyInstance.getPaths().forEach((path, index) => {
        editListener.current = window.google.maps.event.addListener(
          path,
          "set_at",
          () => {
            onUpdateShape(path, false);
          }
        );
      });
    } else {
      window.google.maps.event.removeListener(editListener.current);
    }
  }, [canEdit, onUpdateShape, polyInstance]);

  useEffect(() => {
    if (path && map && pathToUse.length === 0) {
      const newP = rotatePolygon(JSON.parse(path), map, angle || 0);
      setPathToUse(newP);
    }
  }, [path, map, angle, pathToUse, rotatePolygon]);

  if (!path) {
    return null;
  }

  const onEndDrag = (e) => {
    setIsDragging(false);

    onUpdateShape(newPath, true);
  };

  const onDrag = (e) => {
    setNewPath(polyInstance.getPath());
  };

  const onStartDrag = (e) => {
    setIsDragging(true);
  };

  const onPolyLoaded = (poly) => {
    setPolyInstance(poly);
  };

  var rad = (x) => {
    return (x * Math.PI) / 180;
  };

  const rotatePoint = (point, origin, angle) => {
    var angleRad = (angle * Math.PI) / 180.0;
    return {
      x:
        Math.cos(angleRad) * (point.x - origin.x) -
        Math.sin(angleRad) * (point.y - origin.y) +
        origin.x,
      y:
        Math.sin(angleRad) * (point.x - origin.x) +
        Math.cos(angleRad) * (point.y - origin.y) +
        origin.y,
    };
  };

  var getDistance = function (p1, p2) {
    var R = 6378137; // Earth’s mean radius in meter
    var dLat = rad(p2.lat - p1.lat);
    var dLong = rad(p2.lng - p1.lng);
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(rad(p1.lat)) *
        Math.cos(rad(p2.lat)) *
        Math.sin(dLong / 2) *
        Math.sin(dLong / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return d; // returns the distance in meter
  };

  let position = { lat: 0, lng: 0 };

  pathToUse.forEach((point) => {
    position.lat += point.lat;
    position.lng += point.lng;
  });

  position.lat /= 4;
  position.lng /= 4;

  if (pathToUse.length === 0) {
    return null;
  }

  const tooltip = (
    <InfoWindow position={position}>
      <ToolTipWrapper>
        <ToolTipContent>
          <RotateOption
            onClick={() => {
              onUpdatePlaats({ ...plaats, angle: angle - 90 });
            }}
          >
            -90°
          </RotateOption>
          <RotateOption
            onClick={() => {
              onUpdatePlaats({ ...plaats, angle: angle - 15 });
            }}
          >
            -15°
          </RotateOption>
          <RotateOption
            onClick={() => {
              onUpdatePlaats({ ...plaats, angle: angle - 5 });
            }}
          >
            -5°
          </RotateOption>
          <RotateOption
            onClick={() => {
              onUpdatePlaats({ ...plaats, angle: angle - 1 });
            }}
          >
            -1°
          </RotateOption>
          <RotateOption
            onClick={() => {
              onUpdatePlaats({ ...plaats, angle: angle + 1 });
            }}
          >
            +1°
          </RotateOption>
          <RotateOption
            onClick={() => {
              onUpdatePlaats({ ...plaats, angle: angle + 5 });
            }}
          >
            +5°
          </RotateOption>
          <RotateOption
            onClick={() => {
              onUpdatePlaats({ ...plaats, angle: angle + 15 });
            }}
          >
            +15°
          </RotateOption>
          <RotateOption
            onClick={() => {
              onUpdatePlaats({ ...plaats, angle: angle + 90 });
            }}
          >
            +90°
          </RotateOption>
          <DeleteOption
            onClick={() => {
              onUpdatePlaats({ ...plaats, path: "", angle: 0 });
            }}
          >
            <FontAwesomeIcon icon={["fas", "trash-alt"]} />
          </DeleteOption>
        </ToolTipContent>
      </ToolTipWrapper>
    </InfoWindow>
  );

  const pos01 = {
    lat: (pathToUse[0].lat + pathToUse[1].lat) / 2.0,
    lng: (pathToUse[0].lng + pathToUse[1].lng) / 2.0,
  };
  const dist01 = Math.round(getDistance(pathToUse[0], pathToUse[1]) * 10) / 10;

  const pos12 = {
    lat: (pathToUse[1].lat + pathToUse[2].lat) / 2.0,
    lng: (pathToUse[1].lng + pathToUse[2].lng) / 2.0,
  };
  const dist12 = Math.round(getDistance(pathToUse[1], pathToUse[2]) * 10) / 10;

  const pos23 = {
    lat: (pathToUse[2].lat + pathToUse[3].lat) / 2.0,
    lng: (pathToUse[2].lng + pathToUse[3].lng) / 2.0,
  };
  const dist23 = Math.round(getDistance(pathToUse[2], pathToUse[3]) * 10) / 10;

  const pos30 = {
    lat: (pathToUse[3].lat + pathToUse[0].lat) / 2.0,
    lng: (pathToUse[3].lng + pathToUse[0].lng) / 2.0,
  };
  const dist30 = Math.round(getDistance(pathToUse[3], pathToUse[0]) * 10) / 10;

  let x = -5;
  let y = -8;

  if (Standplaats) {
    x = -(4 * Standplaats.toString().length);
    y = Math.sin(angle) * 2;
    if (y === 0) y = -8;
  }

  return (
    <>
      <Polygon
        key={`poly-${Standplaats}`}
        path={pathToUse}
        draggable={canMove}
        editable={canEdit}
        onLoad={onPolyLoaded}
        onDragStart={onStartDrag}
        onDrag={onDrag}
        onDragEnd={onEndDrag}
        onClick={() => {
          setShowTooltip(!showTooltip);
        }}
        options={{
          fillColor: plaats.Bezet === 1 ? "red" : "green",
          strokeWeight: 1,
          strokeOpacity: 0.5,
        }}
      />
      {showTooltip && tooltip}
      {/* <Circle center={pathToUse[3]} radius={0.5} /> */}
      <OverlayView
        position={position}
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      >
        <Background>
          {Standplaats ? (
            zoomLevel > 19 && (
              <Text angle={angle} x={x} y={y}>
                {Standplaats}
              </Text>
            )
          ) : (
            <NumberInput
              autoFocus
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  onUpdatePlaats({
                    ...plaats,
                    Standplaats: e.target.value,
                  });
                }
              }}
            />
          )}
        </Background>
      </OverlayView>
      {canEdit && (
        <>
          <OverlayView
            position={pos01}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
          >
            <div>{dist01}</div>
          </OverlayView>
          <OverlayView
            position={pos12}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
          >
            <div>{dist12}</div>
          </OverlayView>
          <OverlayView
            position={pos23}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
          >
            <div>{dist23}</div>
          </OverlayView>
          <OverlayView
            position={pos30}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
          >
            <div>{dist30}</div>
          </OverlayView>
        </>
      )}
    </>
  );
};

export default AdminStandplaatsMarker;
