import React, { useState } from "react";

// Components
import Button from "components/Button";

// helpers
import { useAuthState } from "stores/AuthStore";
import { motion, AnimatePresence } from "framer-motion";
import { useFormik } from "formik";
import { UpdateAccount } from "api/calls";

// Styling
import styled from "styled-components";

const MainCard = styled.div`
  display: flex;
  flex-flow: column;
  border: 1px solid black;
  border-radius: 2px;
  margin-bottom: 15px;
  margin-right: 15px;
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  background-color: #f5f8fa;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-flow: row;
  padding: 10px;
`;

const InfoText = styled.div`
  flex-basis: 25%;
`;

const Divider = styled(motion.div)`
  height: 1px;
  background-color: black;
`;

const StyledForm = styled.form`
  display: grid;
  grid-template-columns: repeat(3, 33%);
  row-gap: 5px;
  grid-template-rows: repeat(3, 33%);
  margin: 35px;
`;

const FieldWrapper = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;

  width: 100%;
`;

const FieldLabel = styled.label`
  font-size: 18px;
  margin-right: 10px;
`;

const FieldValue = styled.input`
  outline: none;
  user-select: none;
  padding: 5px;
`;

const SaveButton = styled(Button)`
  width: 50%;
  height: 80%;
  margin-top: 10px;
`;

const AccountCard = ({ user }) => {
  const { AuthState } = useAuthState();
  const { token } = AuthState;
  const [isOpen, setIsOpen] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: user.Email,
      gsm: user.GSM,
      street: user.Straat,
      number: user.Huisnummer,
      zipCode: user.Postcode,
      city: user.Gemeente,
      country: user.Land,
    },
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      let newUser = user;
      newUser.Gemeente = values.city;
      newUser.Land = values.country;
      newUser.Email = values.email;
      newUser.Straat = values.street;
      newUser.Huisnummer = values.number;
      newUser.Postcode = values.zipCode;
      newUser.GSM = values.gsm;

      UpdateAccount(user.StandhouderID, newUser, token);
    },
  });

  return (
    <MainCard
      onClick={() => {
        setIsOpen(!isOpen);
      }}
    >
      <InfoWrapper>
        <InfoText>{user.StandhouderID}</InfoText>
        <InfoText>{user.Naam}</InfoText>
        <InfoText>{user.Voornaam}</InfoText>
        <InfoText>{user.Straat}</InfoText>
      </InfoWrapper>
      {isOpen && (
        <AnimatePresence>
          <Divider />
          <StyledForm
            onSubmit={formik.handleSubmit}
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: "auto", opacity: 1 }}
            transition={{ duration: 0.1 }}
            exit={{ height: 0 }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <FieldWrapper>
              <FieldLabel htmlFor="StandhouderID">
                StandhouderId: {user.StandhouderID}
              </FieldLabel>
            </FieldWrapper>
            <FieldWrapper>
              <FieldLabel htmlFor="email">Email:</FieldLabel>
              <FieldValue
                id="email"
                name="email"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.email}
              />
            </FieldWrapper>
            <FieldWrapper>
              <FieldLabel htmlFor="gsm">GSM:</FieldLabel>
              <FieldValue
                id="gsm"
                name="gsm"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.gsm}
              />
            </FieldWrapper>
            <FieldWrapper>
              <FieldLabel htmlFor="street">Straat:</FieldLabel>
              <FieldValue
                id="street"
                name="street"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.street}
              />
            </FieldWrapper>
            <FieldWrapper>
              <FieldLabel htmlFor="number">Huisnummer:</FieldLabel>
              <FieldValue
                id="number"
                name="number"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.number}
              />
            </FieldWrapper>
            <FieldWrapper>
              <FieldLabel htmlFor="zipCode">Postcode:</FieldLabel>
              <FieldValue
                id="zipCode"
                name="zipCode"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.zipCode}
              />
            </FieldWrapper>
            <FieldWrapper>
              <FieldLabel htmlFor="city">Gemeente:</FieldLabel>
              <FieldValue
                id="city"
                name="city"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.city}
              />
            </FieldWrapper>
            <FieldWrapper>
              <FieldLabel htmlFor="country">Land:</FieldLabel>
              <FieldValue
                id="country"
                name="country"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.country}
              />
            </FieldWrapper>
            <SaveButton text="Save" enabled={true} />
          </StyledForm>
        </AnimatePresence>
      )}
    </MainCard>
  );
};

export default AccountCard;
