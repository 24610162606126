import React from "react";

// Styling
import styled from "styled-components";

const MainPageWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 5px 50px;
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  align-items: center;
  overflow: auto;

  @media (max-width: 768px) {
    padding: 20px 15px;
  }
`;

const MainContent = styled.pre`
  width: 100%;
  white-space: pre-wrap;
  font-family: "Open Sans";
  text-align: center;
  word-break: keep-all;
  font-size: 22px;
  padding: 25px;
  background: rgba(0, 0, 0, 0.05);
`;

const LogoBanner = styled.div`
  width: 100%;
  background: linear-gradient(
    90deg,
    rgba(44, 74, 118, 0) 0%,
    rgba(44, 74, 118, 0.75) 40%,
    rgba(44, 74, 118, 1) 50%,
    rgba(44, 74, 118, 0.75) 60%,
    rgba(44, 74, 118, 0) 100%
  );
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 5px;
`;
const Logo = styled.img`
  height: 75px;
`;

const InfoPage = () => {
  return (
    <MainPageWrapper>
      <LogoBanner>
        <Logo src="Logo.png" alt="logo" />
      </LogoBanner>
      <MainContent>
        {`
Beste standhouders


Tot 1 januari 2024 kunnen enkel de bewoners van de straten waarin gemarkt wordt hun plaats
boeken. De prijs blijft 12 euro/6m zoals dit jaar het geval was.

Vanaf 1 januari 2024 tot en met 31 maart 2024 kunnen enkel de standhouders die in 2023 hebben
deelgenomen de plaatsen boeken die ze in 2023 innamen.

Vanaf 1 april 2024 worden de plaatsen vrijgegeven zodat iedereen die dit wenst een plaats kan
reserveren.
        `}
      </MainContent>
    </MainPageWrapper>
  );
};

export default InfoPage;
