import React, { useState, useEffect } from "react";

import { useFormik } from "formik";
import * as Yup from "yup";

// Components
import Button from "components/Button";

// helpers
import localizer, { capitalize } from "localization/localizer";
import { RegisterAccount } from "api/calls";
import md5 from "md5";
import { useNavigate } from "react-router-dom";

// Styling
import styled from "styled-components";

const SignupSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, localizer.tooShort)
    .max(50, localizer.tooLong)
    .required(localizer.required),
  lastName: Yup.string()
    .min(2, localizer.tooShort)
    .max(50, localizer.tooLong)
    .required(localizer.required),
  street: Yup.string().min(2, localizer.tooShort).required(localizer.required),
  number: Yup.string().min(1, localizer.tooShort).required(localizer.required),
  city: Yup.string().min(2, localizer.tooShort).required(localizer.required),
  country: Yup.string().min(2, localizer.tooShort),
  email: Yup.string()
    .email(localizer.invalidEmail)
    .required(localizer.required),
  emailConfirm: Yup.string()
    .email(localizer.invalidEmail)
    .required(localizer.required),
  password: Yup.string()
    .required(localizer.required)
    .min(6, localizer.passwordTooShort),
  passwordConfirm: Yup.string().oneOf(
    [Yup.ref("password"), null],
    localizer.passwordMustMatch
  ),
});

const Title = styled.div`
  font-size: 22px;
  font-weight: 500;
  text-align: center;
  margin-top: 25px;
`;

const PageWrapper = styled.div`
  background: white;
  height: 90%;
  width: 800px;
  margin: 25px;
  margin-left: auto;
  margin-right: auto;
  overflow: auto;
  border-radius: 5px;
  border: 1px solid gray;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
`;

const StyledForm = styled.form`
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 100%;
  min-width: 700px;
  padding-left: 6em;
  padding-right: 6em;
`;

const FieldWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-flow: row;
  margin-top: 15px;
  width: 100%;
`;

const FieldLabel = styled.label`
  font-size: 18px;
  width: 50%;
  margin-right: 10px;
`;

const FieldValue = styled.input`
  outline: none;
  border: 0.15em solid ${(props) => props.theme.primaryColor};
  border-radius: 15px;
  user-select: none;
  padding: 5px;
  background-color: white;
  height: 35px;
  width: 50%;
`;

const FieldError = styled.div`
  color: red;
  width: 40%;
  margin-top: 2px;
  margin-left: 55%;
`;

const SubmitButton = styled(Button)`
  height: 2.5em;
  margin-top: 25px;
  margin-bottom: 15px;
  width: 40%;
  margin-left: auto;
  background-color: ${(props) => props.theme[props.buttonColor]};
`;

const Divider = styled.div`
  height: 1px;
  background-color: black;
  width: 80%;
  margin-bottom: 15px;
  margin-top: 15px;
  margin-left: auto;
  margin-right: auto;
`;

const SignupPage = () => {
  const [processing, setProcessing] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(null);

  const [buttonText, setButtonText] = useState(localizer.confirm);
  const [buttonColor, setButtonColor] = useState("primaryColor");

  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
    },
    validationSchema: SignupSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      values.password = md5(values.password);
      values.passwordConfirm = md5(values.passwordConfirm);

      setProcessing(true);

      RegisterAccount(values)
        .then((result) => {
          setProcessing(false);
          setIsSuccess(true);
        })
        .catch((e) => {
          setTimeout(() => {
            setProcessing(false);
          }, 1000);
          if (e.response) {
            const { data } = e.response;
            if (data.localizer) {
              setIsSuccess(false);
              setErrorMessage(data.localizer);
            }
          }
        });
    },
  });

  useEffect(() => {
    if (errorMessage) {
      if (errorMessage === "emailAlreadyExists") {
        formik.errors.email = localizer[errorMessage];
      }
    } else {
      delete formik.errors.email;
    }
  }, [errorMessage, formik]);

  useEffect(() => {
    if (processing) {
      setButtonText("Processing...");
    } else {
      if (isSuccess) {
        setButtonText("Success");
        setTimeout(() => {
          navigate(`/login`);
        }, 2000);
      } else if (isSuccess === false) {
        setButtonText("Failed");
        setButtonColor("errorColor");
        setTimeout(() => {
          setButtonText(localizer.confirm);
          setButtonColor("primaryColor");
        }, 1500);
      }
    }
  }, [processing, isSuccess, navigate]);

  let content = (
    <StyledForm onSubmit={formik.handleSubmit}>
      <FieldWrapper>
        <FieldLabel htmlFor="firstName">{localizer.firstName}</FieldLabel>
        <FieldValue
          id="firstName"
          name="firstName"
          type="text"
          onChange={formik.handleChange}
          value={formik.values.firstName}
        />
      </FieldWrapper>
      <FieldError>{formik.errors.firstName}</FieldError>
      <FieldWrapper>
        <FieldLabel htmlFor="lastName">{localizer.lastName}</FieldLabel>
        <FieldValue
          id="lastName"
          name="lastName"
          type="text"
          onChange={formik.handleChange}
          value={formik.values.lastName}
        />
      </FieldWrapper>
      <FieldError>{formik.errors.lastName}</FieldError>
      <FieldWrapper>
        <FieldLabel htmlFor="street">{localizer.street}</FieldLabel>
        <FieldValue
          id="street"
          name="street"
          type="text"
          onChange={formik.handleChange}
          value={formik.values.street}
        />
      </FieldWrapper>
      <FieldError>{formik.errors.street}</FieldError>
      <FieldWrapper>
        <FieldLabel htmlFor="number">{localizer.number}</FieldLabel>
        <FieldValue
          id="number"
          name="number"
          type="text"
          onChange={formik.handleChange}
          value={formik.values.number}
        />
      </FieldWrapper>
      <FieldError>{formik.errors.number}</FieldError>
      <FieldWrapper>
        <FieldLabel htmlFor="zipCode">{localizer.zipCode}</FieldLabel>
        <FieldValue
          id="zipCode"
          name="zipCode"
          type="text"
          onChange={formik.handleChange}
          value={formik.values.zipCode}
        />
      </FieldWrapper>
      <FieldError>{formik.errors.zipCode}</FieldError>
      <FieldWrapper>
        <FieldLabel htmlFor="city">{localizer.city}</FieldLabel>
        <FieldValue
          id="city"
          name="city"
          type="text"
          onChange={formik.handleChange}
          value={formik.values.city}
        />
      </FieldWrapper>
      <FieldError>{formik.errors.city}</FieldError>
      <FieldWrapper>
        <FieldLabel htmlFor="country">{localizer.country}</FieldLabel>
        <FieldValue
          id="country"
          name="country"
          type="text"
          onChange={formik.handleChange}
          value={formik.values.country}
        />
      </FieldWrapper>
      <FieldError>{formik.errors.country}</FieldError>
      <FieldWrapper>
        <FieldLabel htmlFor="phoneNumber">{localizer.phoneNumber}</FieldLabel>
        <FieldValue
          id="phoneNumber"
          name="phoneNumber"
          type="text"
          onChange={formik.handleChange}
          value={formik.values.phoneNumber}
        />
      </FieldWrapper>
      <FieldError>{formik.errors.phoneNumber}</FieldError>
      <FieldWrapper>
        <FieldLabel htmlFor="email">
          {capitalize(localizer.emailAddress)}
        </FieldLabel>
        <FieldValue
          id="email"
          name="email"
          type="email"
          onChange={(e) => {
            if (errorMessage) {
              setErrorMessage("");
            }
            formik.handleChange(e);
          }}
          value={formik.values.email}
        />
      </FieldWrapper>
      <FieldError>{formik.errors.email}</FieldError>
      <FieldWrapper>
        <FieldLabel htmlFor="emailConfirm">{`${localizer.confirm} ${localizer.emailAddress}`}</FieldLabel>
        <FieldValue
          id="emailConfirm"
          name="emailConfirm"
          type="email"
          onChange={formik.handleChange}
          value={formik.values.emailConfirm}
        />
      </FieldWrapper>
      <FieldError>{formik.errors.emailConfirm}</FieldError>
      <FieldWrapper>
        <FieldLabel htmlFor="password">
          {capitalize(localizer.password)}
        </FieldLabel>
        <FieldValue
          id="password"
          name="password"
          type="password"
          onChange={formik.handleChange}
        />
      </FieldWrapper>
      <FieldError>{formik.errors.password}</FieldError>
      <FieldWrapper>
        <FieldLabel htmlFor="passwordConfirm">{`${localizer.confirm} ${localizer.password}`}</FieldLabel>
        <FieldValue
          id="passwordConfirm"
          name="passwordConfirm"
          type="password"
          onChange={formik.handleChange}
        />
      </FieldWrapper>
      <FieldError>{formik.errors.passwordConfirm}</FieldError>
      <SubmitButton
        type="submit"
        text={buttonText}
        onClick={() => {}}
        buttonColor={buttonColor}
        enabled={processing ? false : true}
      />
    </StyledForm>
  );

  return (
    <PageWrapper>
      <Title>{localizer.signup}</Title>
      <Divider />
      {content}
    </PageWrapper>
  );
};

export default SignupPage;
