import React, { useEffect, useState } from "react";

// Helpers
import { useAuthState } from "stores/AuthStore";
import { GetStandplaatsenForID } from "api/calls";
import localizer from "localization/localizer";
import Button from "components/Button";

import { useNavigate } from "react-router-dom";

// Styling
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

const Title = styled.div`
  font-size: 30px;
  font-weight: 400;
  padding: 10px 25px;
  text-align: center;
  border-radius: 3px;
  border: none;
  margin: 10px;
  width: 95%;
  color: white;
  background: rgba(44, 74, 118, 0.75);
`;

const Plaats = styled.div`
  font-size: 24px;
`;

const Straat = styled.div`
  font-size: 18px;
`;

const Label = styled.div`
  margin-bottom: 10px;
  font-weight: bold;
`;

const PlaatsWrapper = styled.div`
  display: flex;
  flex-flow: row;
  margin-bottom: 10px;
  width: 50%;
  border: 0.15em solid ${(props) => props.theme.primaryColor};
  border-radius: 5px;
  padding: 10px 15px;
  justify-content: space-between;
  align-items: center;
`;

const PlaatsenWrapper = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-top: 25px;
  padding-bottom: 25px;
`;

const ConfirmButton = styled(Button)`
  height: 2.5em;
  width: 30%;
  margin-top: auto;
  margin-bottom: 10px;
`;

const CurrentPlaces = () => {
  const { AuthState } = useAuthState();
  const { user, token } = AuthState;

  const [plaatsen, setPlaatsen] = useState([]);
  const [phase, setPhase] = useState(0);

  const navigate = useNavigate();

  // user.placesLastYear
  // user.isPersonFromNeighbourhood

  useEffect(() => {
    const today = new Date();

    let p = 0;
    if (today.getMonth() + 1 >= 11 && today.getMonth() <= 12) {
      p = 1;
    } else if (today.getMonth() + 1 >= 1 && today.getMonth() + 1 <= 3) {
      p = 2;
    } else {
      p = 3;
    }

    setPhase(p);
  }, []);

  useEffect(() => {
    if (user && token) {
      GetStandplaatsenForID(user.StandhouderID, token).then((result) => {
        const { data } = result;

        setPlaatsen(data);
      });
    }
  }, [user, token]);

  let content;
  let showReserveButton = false;
  if (plaatsen && Array.isArray(plaatsen)) {
    content = plaatsen?.map((plaats, index) => {
      return (
        <>
          <Label> {localizer.accountStatusInfo.placesThisEdition}</Label>
          <PlaatsWrapper key={`lastplace-${index}`}>
            <Straat>{plaats.Straat}</Straat>
            <Plaats>{plaats.Standplaats}</Plaats>
          </PlaatsWrapper>
        </>
      );
    });
  } else {
    switch (phase) {
      default:
      case 1:
        if (user.isPersonFromNeighbourhood) {
          content = localizer.accountStatusInfo.noPlacesNextEdition.afterApril;
        } else if (user.placesLastYear?.length > 0) {
          content =
            "U heeft nog geen plaatsen gereserveerd voor volgende editie, u kan reserveren vanaf 1 januari";
        } else {
          content =
            "U heeft nog geen plaatsen gereserveerd voor volgende editie, u kan reserveren vanaf 1 april";
        }
        break;
      case 2:
        if (user.isPersonFromNeighbourhood) {
          content = localizer.accountStatusInfo.noPlacesNextEdition.afterApril;
          showReserveButton = true;
        } else if (user.placesLastYear?.length > 0) {
          content =
            localizer.accountStatusInfo.noPlacesNextEdition.placesLastYear;
          showReserveButton = true;
        } else {
          content =
            localizer.accountStatusInfo.noPlacesNextEdition.noPlacesLastYear;
        }
        break;
      case 3:
        content = localizer.accountStatusInfo.noPlacesNextEdition.afterApril;
        showReserveButton = true;
        break;
    }
  }

  return (
    <Wrapper>
      <Title>{`${localizer.garageSale} 2024`}</Title>
      <PlaatsenWrapper>
        {user.placesLastYear?.length > 0 ? (
          <>
            <Label>{localizer.accountStatusInfo.placesLastEdition}</Label>
            {user.placesLastYear
              .sort((a, b) => {
                return a.Standplaats > b.Standplaats ? 1 : -1;
              })
              .map((x, index) => {
                return (
                  <>
                    <PlaatsWrapper key={`lastplace-${index}`}>
                      <Straat>{x.Straat}</Straat>
                      <Plaats>{x.Standplaats}</Plaats>
                    </PlaatsWrapper>
                  </>
                );
              })}
          </>
        ) : (
          localizer.accountStatusInfo.noPlacesLastEdition
        )}
      </PlaatsenWrapper>
      <PlaatsenWrapper>{content}</PlaatsenWrapper>
      {showReserveButton && (
        <ConfirmButton
          onClick={() => {
            navigate(`/reserve`);
          }}
          text={
            user.placesLastYear?.length > 0
              ? localizer.accountStatusInfo.reserveSamePlaces
              : localizer.accountStatusInfo.reservePlaces
          }
          enabled={true}
        />
      )}
    </Wrapper>
  );
};

export default CurrentPlaces;
