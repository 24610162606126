import React from "react";
const RedirectPage = () => {
  window.open(
    "https://www.your-tickets.be/events/AmbianceoptKaaiken2024",
    "_self"
  );
  return <></>;
};

export default RedirectPage;
