import React, { useEffect, useRef, useState } from "react";

// Components
import RommelmarktVideo from "resources/PromoForSite.mp4";
import RommelmarktSnap from "resources/promo_snap.png";
import ProgrammaVideo from "resources/programma.mp4";
import ProgrammaSnap from "resources/programma_snap.png";

// Helpers
import { useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";

// Styling
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-flow: row;
  width: 100vw;
  height: 100vh;

  @media (max-width: 768px) {
    flex-flow: column;
    align-items: center;
    justify-content: center;
  }
`;

const Programma = styled.div`
  transition: 1s ease-out;
  position: relative;
  overflow: hidden;
  cursor: none;

  @media (max-width: 3600px) {
    width: ${({ hovered }) => {
      return hovered ? 70 : 30;
    }}%;
  }

  @media (max-width: 768px) {
    width: 100%;
    height: ${({ hovered }) => {
      return hovered ? 70 : 30;
    }}%;
  }
`;

const Rommelmarkt = styled.div`
  transition: 1s ease-out;
  position: relative;
  overflow: hidden;
  cursor: none;

  @media (max-width: 3600px) {
    width: ${({ hovered }) => {
      return hovered ? 70 : 30;
    }}%;
  }

  @media (max-width: 768px) {
    width: 100%;
    height: ${({ hovered }) => {
      return hovered ? 70 : 30;
    }}%;
  }
`;

const StyledVideo = styled.video`
  position: absolute;
  top: -100%;
  bottom: -100%;
  left: -100%;
  right: -100%;
  margin: auto;
  height: 100%;
  max-width: none;
  transition: width 1s ease, height 1s ease;
`;

const Label = styled.div`
  font-size: 2.5rem;
  position: relative;
  width: fit-content;
  left: 50%;
  bottom: -${({ bottom }) => bottom}%;
  transform: translateX(-50%);
  padding: 10px 50px;
  color: white;
  text-shadow: 1px 1px 0 #000, -1px -1px 0 #000, 1px -1px 0 #000,
    -1px 1px 0 #000, 1px 1px 0 #000;
  font-family: "Trebuchet MS", "Courier New", monospace;
  z-index: 10;
  user-select: none;
`;

const Button = styled.div`
  border: 1px solid white;
  position: absolute;
  user-select: none;
  font-size: 2rem;
  border-radius: 20px;
  color: white;
  padding: 10px 30px;
  pointer-events: none;
  background: rgba(44, 74, 118, 0.75);
  text-shadow: 1px 1px 2px black;
  z-index: 10;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

const StyledImage = styled.img`
  position: absolute;
  top: -100%;
  bottom: -100%;
  left: -100%;
  right: -100%;
  margin: auto;
  height: 100%;
  max-width: none;
  transition: width 1s ease, height 1s ease;
  z-index: 5;
`;

const HomePage = () => {
  const [hovered, setHovered] = useState("programma");
  const [buttonText, setButtonText] = useState("Bekijk het programma ->");

  const [programVideoLoaded, setProgramVideoLoaded] = useState(false);
  const [rommelmarktVideoLoaded, setRommelmarktVideoLoaded] = useState(false);

  const navigate = useNavigate();

  const programmaVideoRef = useRef();
  const rommelmarktVideoRef = useRef();
  const buttonRef = useRef();

  const moveCursor = (e) => {
    const mouseY = e.clientY;
    const mouseX = e.clientX;
    if (buttonRef.current) {
      buttonRef.current.style.transform = `translate(calc(${mouseX}px - 50%), calc(${mouseY}px - 50%))`;
    }
  };

  useEffect(() => {
    if (buttonRef.current && !isMobile) {
      window.addEventListener("mousemove", moveCursor);
    }
  }, [buttonRef]);

  useEffect(() => {
    if (rommelmarktVideoRef.current && hovered === "rommelmarkt") {
      rommelmarktVideoRef.current.playbackRate = 0.9;
      rommelmarktVideoRef.current.play();
      programmaVideoRef.current.pause();
    } else if (programmaVideoRef.current && hovered === "programma") {
      programmaVideoRef.current.playbackRate = 0.9;
      programmaVideoRef.current.play();
      rommelmarktVideoRef.current.pause();
    }
  }, [rommelmarktVideoRef, programmaVideoRef, hovered]);
  return (
    <Wrapper>
      <Programma
        onMouseEnter={() => {
          setHovered("programma");
          setButtonText("Bekijk het programma ->");
        }}
        hovered={hovered === "programma"}
        onClick={() => {
          if (!isMobile) {
            window.removeEventListener("mousemove", moveCursor);
            navigate("program");
          }
        }}
        onTouchStart={(e) => {
          e.preventDefault();
          e.stopPropagation();

          if (hovered === "programma") {
            navigate("program");
          }

          setHovered("programma");
          if (buttonRef.current) {
            buttonRef.current.style.transform = `translate(0px,-50%)`;
          }
        }}
      >
        {!programVideoLoaded && <StyledImage src={ProgrammaSnap} />}
        <StyledVideo
          hovered={true}
          onContextMenu={(e) => {
            e.preventDefault();
          }}
          onCanPlay={() => {
            setProgramVideoLoaded(true);
          }}
          ref={programmaVideoRef}
          src={ProgrammaVideo}
          type="video/mp4"
          muted
          autoplay
          loop
        />
        <Label bottom={80}>Programma</Label>
      </Programma>
      <Rommelmarkt
        onMouseEnter={() => {
          setHovered("rommelmarkt");
          setButtonText("Schrijf je in ->");
        }}
        hovered={hovered === "rommelmarkt"}
        onClick={() => {
          if (!isMobile) {
            window.removeEventListener("mousemove", moveCursor);
            navigate("market");
          }
        }}
        onTouchStart={(e) => {
          e.preventDefault();
          e.stopPropagation();

          if (hovered === "rommelmarkt") {
            navigate("market");
          }

          setHovered("rommelmarkt");
          if (buttonRef.current) {
            buttonRef.current.style.transform = `translate(0px, 100%)`;
          }
        }}
      >
        {!rommelmarktVideoLoaded && <StyledImage src={RommelmarktSnap} />}
        <StyledVideo
          onContextMenu={(e) => {
            e.preventDefault();
          }}
          onCanPlay={() => {
            setRommelmarktVideoLoaded(true);
          }}
          ref={rommelmarktVideoRef}
          src={RommelmarktVideo}
          type="video/mp4"
          muted
          autoplay
          loop
        />
        <Label bottom={isMobile ? 0 : 80}>Rommelmarkt</Label>
      </Rommelmarkt>

      <Button
        ref={buttonRef}
        onClick={() => {
          if (isMobile) {
            if (hovered === "programma") {
              navigate("program");
            } else if (hovered === "rommelmarkt") {
              navigate("market");
            }
          }
        }}
      >
        {buttonText}
      </Button>
    </Wrapper>
  );
};

export default HomePage;
