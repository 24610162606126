import React, { useEffect, useState, useCallback } from "react";

import { Polygon, OverlayView } from "@react-google-maps/api";

import styled from "styled-components";

const Background = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Text = styled.div`
  font-size: 18px;
  font-weight: 500;
  /* transform: ${(props) =>
    `rotate(${props.angle}deg) translate(${props.x}px, ${props.y}px)`}; */
  transform: ${(props) => `translate(${props.x}px, 0px)`};
`;

const StandplaatsMarker = ({
  plaats,
  zoomLevel,
  map,
  onSelect,
  onDeselect,
  isSelected,
}) => {
  const { Standplaats, path, angle } = plaats;
  const [pathToUse, setPathToUse] = useState([]);

  const rotatePoint = (point, origin, angle) => {
    var angleRad = (angle * Math.PI) / 180.0;
    return {
      x:
        Math.cos(angleRad) * (point.x - origin.x) -
        Math.sin(angleRad) * (point.y - origin.y) +
        origin.x,
      y:
        Math.sin(angleRad) * (point.x - origin.x) +
        Math.cos(angleRad) * (point.y - origin.y) +
        origin.y,
    };
  };

  const rotatePolygon = useCallback((path, map, a) => {
    const or = new window.google.maps.LatLng(path[0].lat, path[0].lng);
    var prj = map.getProjection();
    var origin = prj.fromLatLngToPoint(or); //rotate around first point

    var coords = path.map((latLng) => {
      const p = new window.google.maps.LatLng(latLng.lat, latLng.lng);
      var point = prj.fromLatLngToPoint(p);
      var rotatedLatLng = prj.fromPointToLatLng(rotatePoint(point, origin, a));
      return { lat: rotatedLatLng.lat(), lng: rotatedLatLng.lng() };
    });

    return coords;
  }, []);

  useEffect(() => {
    if (path && map && pathToUse.length === 0) {
      const newP = rotatePolygon(JSON.parse(path), map, angle || 0);
      setPathToUse(newP);
    }
  }, [path, map, angle, pathToUse, rotatePolygon]);

  if (!path) {
    return null;
  }

  let position = { lat: 0, lng: 0 };

  pathToUse.forEach((point) => {
    position.lat += point.lat;
    position.lng += point.lng;
  });

  position.lat /= 4;
  position.lng /= 4;

  let x = -5;
  let y = -8;

  let desiredColor = "green";
  if (isSelected) {
    desiredColor = "blue";
  } else {
    switch (plaats.Bezet) {
      default:
      case 0:
        desiredColor = "green";
        break;
      case 1:
        desiredColor = "red";
        break;
      case 2:
        desiredColor = "orange";
        break;
    }
  }

  return (
    <>
      <Polygon
        key={`poly-${Standplaats}`}
        path={pathToUse}
        onClick={() => {
          if (plaats.Bezet === 0) {
            if (isSelected) {
              onDeselect(plaats);
            } else {
              onSelect(plaats);
            }
          }
        }}
        options={{
          fillColor: desiredColor,
          strokeWeight: 1,
          strokeOpacity: 0.5,
        }}
      />
      <OverlayView
        position={position}
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      >
        <Background>
          {zoomLevel > 19 && (
            <Text angle={angle} x={x} y={y}>
              {Standplaats}
            </Text>
          )}
        </Background>
      </OverlayView>
    </>
  );
};

export default StandplaatsMarker;
