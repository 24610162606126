import React from "react";

// Components
import CardSlider from "./CardSlider";

import Danny from "resources/zaterdag/Danny.jpg";

// Sunday
import Rommelmarkt from "resources/zondag/Rommelmarkt.png";
import Margriet from "resources/zondag/Margriet.jpg";
import Christoff from "resources/zondag/Christoff.jpg";
import Lindsay from "resources/zondag/Lindsay.jpg";
import GarryHagger from "resources/zondag/GarryHagger.png";

import { useNavigate } from "react-router-dom";

import styled from "styled-components";

const Title = styled.h3`
  text-align: center;
`;

const SundayPage = () => {
  const navigate = useNavigate();

  const sundayCards = [
    {
      title: "9u30 - Rommelmarkt",
      image: Rommelmarkt,
    },
    {
      title: "10u - Danny’s\n Guitar Sound",
      image: Danny,
      down: 150,
      twoLines: true,
    },
    // {
    //   title: "18u - Kurt Burgelman",
    //   image: Kurt,
    // },
    {
      title: "19u15- Margriet Hermans\n & Celien",
      image: Margriet,
      twoLines: true,
    },
    {
      title: `21u - Garry Hagger`,
      image: GarryHagger,
      down: 200,
    },
    {
      title: "22u - Lindsey",
      image: Lindsay,
    },
    {
      title: "23u - Christoff",
      image: Christoff,
      down: 150,
    },
  ];

  return (
    <>
      <Title>Zondag 01/10</Title>
      <CardSlider
        key="sunday"
        cards={sundayCards}
        onBackToOverview={() => {
          navigate("..");
        }}
        onRedirect={() => {}}
      />
    </>
  );
};

export default SundayPage;
