import React, { useReducer, createContext, useContext } from "react";

import Cookies from "universal-cookie";

export const AuthStoreContext = createContext();

/**
 * Store containing the information of the currently logged in user.
 * All children element under this component will get access to this information.
 * To get those from a functional component, you can use the useAuthState function.
 * @param {Object} props Only used to get all the children element of this component
 */
const AuthStore = (props) => {
  const cookies = new Cookies();

  const [AuthState, AuthStateDispatch] = useReducer(
    (state, action) => {
      switch (action?.type) {
        case "authenticate":
          cookies.set("authenticated", true);
          cookies.set("token", action.payload.token);
          cookies.set("email", action.payload.user.Email);
          return action.payload;
        case "update":
          return action.payload;
        case "logout":
          cookies.remove("authenticated");
          cookies.remove("token");
          cookies.remove("email");
          return {
            user: {},
            authenticated: false,
          };
        case "streetChosen":
          return {
            ...state,
            street: action.payload,
          };
        default:
          return state;
      }
    },
    {
      user: {},
      authenticated: false,
      admin: false,
      street: "",
    }
  );

  return (
    <AuthStoreContext.Provider value={{ AuthState, AuthStateDispatch }}>
      {props.children}
    </AuthStoreContext.Provider>
  );
};

export const useAuthState = () => useContext(AuthStoreContext);

export default AuthStore;
