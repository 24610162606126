import React from "react";

// Styling
import styled from "styled-components";

const Main = styled.button`
  background-color: ${(props) => (props.enabled ? "#2c4a76" : "#c0c0c0")};
  opacity: ${(props) => (props.enabled ? 1.0 : 0.3)};
  border: 0.15em solid #2c4a76;
  border-radius: 15px;
  padding: 5px 10px;
  user-select: none;
  outline: none;
  color: white;
  margin-top: 20px;
  cursor: ${(props) => (props.enabled ? "pointer" : "default")};
`;

const Button = ({ text, enabled, className, onClick, type }) => {
  return (
    <Main enabled={enabled} className={className} onClick={onClick} type={type}>
      {text}
    </Main>
  );
};

export default Button;
