import React, { useState, useEffect, useCallback } from "react";
import configs from "config.json";

// Components
import Page from "pages/Page";
import { GoogleMap, useLoadScript } from "@react-google-maps/api";
import AdminStandplaatsMarker from "components/AdminStandplaatsMarker";

// helpers
import { GetAllStandplaatsen, UpdateStandplaatsen } from "api/calls";
import { useAuthState } from "stores/AuthStore";

const center = {
  lat: 51.176273,
  lng: 3.56882,
};

const width = 0.000043;
const height = 0.0000114;

const MarketPage = () => {
  const loaderId = "loader-maps-kaaiken";
  const mapId = "maps-kaaiken";
  const googleMapsLibraries = ["drawing", "visualization", "places"];

  const { AuthState } = useAuthState();
  const { token } = AuthState;

  const [backendPlaatsen, setBackendPlaatsen] = useState([]);

  const [standplaatsen, setStandplaatsen] = useState([]);
  const [nieuweStandplaatsen, setNieuweStandplaatsen] = useState([]);

  const [standplaatsMarkers, setStandplaatsMarkers] = useState([]);
  const [nieuweStandplaatsMarkers, setNieuweStandplaatsMarkers] = useState([]);

  const [canCreate, setCanCreate] = useState(false);
  const [canMove, setCanMove] = useState(false);
  const [canEdit, setCanEdit] = useState(false);

  const [isSaving, setIsSaving] = useState(false);
  const [init, setInit] = useState(true);

  const [mapInstance, setMapInstance] = useState();
  const [zoomLevel, setZoomLevel] = useState(21);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: configs.googleMapsApiKey,
    id: loaderId,
    version: "weekly",
    preventGoogleFontsLoading: true,
    googleMapsLibraries,
  });

  const onMapLoad = (instance) => {
    setMapInstance(instance);
  };

  const resetMap = useCallback(() => {
    GetAllStandplaatsen(token).then((result) => {
      if (result.data) {
        setStandplaatsen((data) => [...result.data]);
        setBackendPlaatsen(result.data);
      }
    });
    setNieuweStandplaatsen([]);
  }, [token]);

  const createBasicDiv = () => {
    const divke = document.createElement("div");
    divke.style.backgroundColor = "#fff";
    divke.style.border = "2px solid #fff";
    divke.style.borderRadius = "3px";
    divke.style.boxShadow = "0 2px 6px rgba(0,0,0,.3)";
    divke.style.cursor = "pointer";
    divke.style.marginBottom = "22px";
    divke.style.textAlign = "center";
    divke.style.userSelect = "none";

    return divke;
  };

  const createBasicText = () => {
    const divke = document.createElement("div");

    divke.style.color = "rgb(25,25,25)";
    divke.style.fontFamily = "Roboto,Arial,sans-serif";
    divke.style.fontSize = "16px";
    divke.style.lineHeight = "38px";
    divke.style.paddingLeft = "5px";
    divke.style.paddingRight = "5px";
    divke.style.userSelect = "none";

    return divke;
  };

  const saveControl = useCallback((controlDiv) => {
    // Set CSS for the control border.
    const controlUI = createBasicDiv();
    controlUI.title = "Click to save the map";

    controlDiv.appendChild(controlUI);
    // Set CSS for the control interior.
    const controlText = createBasicText();
    controlText.innerHTML = "Save";
    controlUI.appendChild(controlText);

    // Setup the click event listeners: simply set the map to Chicago.
    controlUI.addEventListener("click", () => {
      setIsSaving(true);
    });
  }, []);

  const createControl = useCallback((controlDiv) => {
    // Set CSS for the control border.
    const controlUI = createBasicDiv();
    controlUI.title = "Klik om een spot aan te maken";

    controlUI.style.backgroundColor = "rgba(255,0,0,0.75)";

    controlDiv.appendChild(controlUI);
    // Set CSS for the control interior.
    const controlText = createBasicText();
    controlText.innerHTML = "Plaats maken";
    controlUI.appendChild(controlText);

    // Setup the click event listeners: simply set the map to Chicago.
    controlUI.addEventListener("click", () => {
      setCanCreate((current) => {
        if (!current) {
          controlUI.style.backgroundColor = "rgba(0,255,0,0.75)";
        } else {
          controlUI.style.backgroundColor = "rgba(255,0,0,0.75)";
        }
        return !current;
      });
    });
  }, []);

  const resetControl = useCallback(
    (controlDiv) => {
      // Set CSS for the control border.
      const controlUI = createBasicDiv();
      controlUI.title = "Klik voor te resetten";

      controlDiv.appendChild(controlUI);
      // Set CSS for the control interior.
      const controlText = createBasicText();
      controlText.innerHTML = "Reset";
      controlUI.appendChild(controlText);

      // Setup the click event listeners: simply set the map to Chicago.
      controlUI.addEventListener("click", () => {
        resetMap();
      });
    },
    [resetMap]
  );

  const moveControl = useCallback((controlDiv) => {
    // Set CSS for the control border.
    const controlUI = createBasicDiv();
    controlUI.title = "Klik om spots te verplaatsen";

    controlUI.style.backgroundColor = "rgba(255,0,0,0.75)";

    controlDiv.appendChild(controlUI);
    // Set CSS for the control interior.
    const controlText = createBasicText();
    controlText.innerHTML = "Plaats verplaatsen";
    controlUI.appendChild(controlText);

    // Setup the click event listeners: simply set the map to Chicago.
    controlUI.addEventListener("click", () => {
      setCanMove((current) => {
        if (!current) {
          controlUI.style.backgroundColor = "rgba(0,255,0,0.75)";
        } else {
          controlUI.style.backgroundColor = "rgba(255,0,0,0.75)";
        }
        return !current;
      });
    });
  }, []);

  const editControl = useCallback((controlDiv) => {
    // Set CSS for the control border.
    const controlUI = createBasicDiv();
    controlUI.title = "Klik om spots aan te passen";

    controlUI.style.backgroundColor = "rgba(255,0,0,0.75)";

    controlDiv.appendChild(controlUI);
    // Set CSS for the control interior.
    const controlText = createBasicText();
    controlText.innerHTML = "Plaats aanpassen";
    controlUI.appendChild(controlText);

    // Setup the click event listeners: simply set the map to Chicago.
    controlUI.addEventListener("click", () => {
      setCanEdit((current) => {
        if (!current) {
          controlUI.style.backgroundColor = "rgba(0,255,0,0.75)";
        } else {
          controlUI.style.backgroundColor = "rgba(255,0,0,0.75)";
        }
        return !current;
      });
    });
  }, []);

  useEffect(() => {
    if (mapInstance && init && token) {
      GetAllStandplaatsen(token).then((result) => {
        if (result.data) {
          setStandplaatsen(result.data);
          setBackendPlaatsen(result.data);
        }
      });

      const centerControlDiv = document.createElement("div");
      const createControlDiv = document.createElement("div");
      const moveControlDiv = document.createElement("div");
      const editControlDiv = document.createElement("div");
      const resetControlDiv = document.createElement("div");

      createControlDiv.style.marginLeft = "10px";
      moveControlDiv.style.marginLeft = "10px";
      editControlDiv.style.marginLeft = "10px";
      resetControlDiv.style.marginLeft = "10px";

      saveControl(centerControlDiv);
      createControl(createControlDiv);
      moveControl(moveControlDiv);
      editControl(editControlDiv);
      resetControl(resetControlDiv);

      mapInstance.controls[
        window.google.maps.ControlPosition.BOTTOM_CENTER
      ].push(centerControlDiv);

      mapInstance.controls[
        window.google.maps.ControlPosition.BOTTOM_CENTER
      ].push(createControlDiv);

      mapInstance.controls[
        window.google.maps.ControlPosition.BOTTOM_CENTER
      ].push(moveControlDiv);

      mapInstance.controls[
        window.google.maps.ControlPosition.BOTTOM_CENTER
      ].push(editControlDiv);

      mapInstance.controls[
        window.google.maps.ControlPosition.BOTTOM_CENTER
      ].push(resetControlDiv);

      setInit(false);
    }
  }, [
    mapInstance,
    init,
    token,
    saveControl,
    createControl,
    editControl,
    moveControl,
    resetControl,
  ]);

  useEffect(() => {
    const plaatsMarkers = standplaatsen
      .map((plaats, index) => {
        if (plaats.path !== "") {
          return (
            <AdminStandplaatsMarker
              key={`plaats-${index}-${Date.now()}`}
              plaats={plaats}
              canMove={canMove}
              canEdit={canEdit}
              zoomLevel={zoomLevel}
              map={mapInstance}
              onUpdatePlaats={(newPlaats) => {
                setStandplaatsen((plaatsen) => {
                  const newList = plaatsen.filter((p, id) => id !== index);
                  return [...newList, newPlaats];
                });
              }}
            />
          );
        } else {
          return null;
        }
      })
      .filter((plaats) => plaats !== null);

    setStandplaatsMarkers(plaatsMarkers);
  }, [standplaatsen, canMove, canEdit, zoomLevel, mapInstance]);

  useEffect(() => {
    const nieuwePlaatsMarkers = nieuweStandplaatsen.map((plaats, index) => {
      return (
        <AdminStandplaatsMarker
          key={`nieuweplaats-${index}-${Date.now()}`}
          plaats={plaats}
          canMove={canMove}
          canEdit={canEdit}
          zoomLevel={zoomLevel}
          map={mapInstance}
          onUpdatePlaats={(newPlaats) => {
            setNieuweStandplaatsen((plaatsen) => {
              const newList = plaatsen.filter((p, id) => id !== index);
              return [...newList, newPlaats];
            });
          }}
        />
      );
    });

    setNieuweStandplaatsMarkers(nieuwePlaatsMarkers);
  }, [nieuweStandplaatsen, canMove, canEdit, zoomLevel, mapInstance]);

  useEffect(() => {
    if (isSaving) {
      const plaatsen = standplaatsen
        .map((plaats) => {
          let match = null;
          nieuweStandplaatsen.forEach((p) => {
            if (p.Standplaats === plaats.Standplaats) {
              match = p;
            }
          });

          // There is a new place created on the map
          if (match) {
            return { ...plaats, ...match };
          }

          backendPlaatsen.forEach((p) => {
            if (p.Standplaats === plaats.Standplaats) {
              if (p.path !== plaats.path || p.angle !== plaats.angle) {
                match = plaats;
              }
            }
          });

          // Something changed to the place
          if (match) {
            return { ...plaats, ...match };
          }

          return null;
        })
        .filter((plaats) => plaats !== null);

      UpdateStandplaatsen(plaatsen, token).then((x) => {
        setTimeout(() => {
          GetAllStandplaatsen(token).then((result) => {
            if (result.data) {
              setStandplaatsen(result.data);
              setNieuweStandplaatsen([]);
              setBackendPlaatsen(result.data);
            }
          });
        }, 1000);
      });

      setIsSaving(false);
    }
  }, [nieuweStandplaatsen, standplaatsen, backendPlaatsen, isSaving, token]);

  if (!isLoaded || loadError) {
    return null;
  }

  return (
    <Page>
      <GoogleMap
        id={mapId}
        center={center}
        onLoad={onMapLoad}
        onZoomChanged={() => {
          if (mapInstance) {
            setZoomLevel(mapInstance.zoom);
          }
        }}
        onClick={(e) => {
          if (canCreate) {
            const lat = e.latLng.lat();
            const lng = e.latLng.lng();

            const path = [
              {
                lat: lat - height,
                lng: lng - width,
              },
              {
                lat: lat - height,
                lng: lng + width,
              },
              {
                lat: lat + height,
                lng: lng + width,
              },
              {
                lat: lat + height,
                lng: lng - width,
              },
            ];

            setNieuweStandplaatsen((plaatsen) => [
              ...plaatsen,
              {
                path: JSON.stringify(path),
              },
            ]);
          }
        }}
        mapContainerStyle={{
          height: "100%",
          width: "100%",
        }}
        zoom={21}
      >
        {standplaatsMarkers}
        {nieuweStandplaatsMarkers}
      </GoogleMap>
    </Page>
  );
};

export default MarketPage;
