import React, { useState, useEffect } from "react";

// helpers
import {
  GetAllAccounts,
  GetAllStandhouders,
  GetAllStandplaatsen,
  AddToMarkt,
} from "api/calls";
import { useFormik } from "formik";
import * as Yup from "yup";
import md5 from "md5";

// Helpers
import { useAuthState } from "stores/AuthStore";
import { RegisterAccount } from "api/calls";

import localizer, { capitalize } from "localization/localizer";

// Styling
import styled from "styled-components";
import AccountCard from "./AccountCard";
import StandhouderCard from "./StandhouderCard";
import StandplaatsCard from "./StandplaatsCard";
import StandhouderEditCard from "./StandhouderEditCard";
import Button from "components/Button";

const SignupSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, localizer.tooShort)
    .max(50, localizer.tooLong)
    .required(localizer.required),
  lastName: Yup.string()
    .min(2, localizer.tooShort)
    .max(50, localizer.tooLong)
    .required(localizer.required),
  street: Yup.string().min(2, localizer.tooShort).required(localizer.required),
  number: Yup.string().min(2, localizer.tooShort).required(localizer.required),
  city: Yup.string().min(2, localizer.tooShort).required(localizer.required),
  country: Yup.string().min(2, localizer.tooShort),
});

const StyledForm = styled.form`
  display: grid;
  grid-template-columns: repeat(4, 25%);
  grid-template-rows: repeat(3, 33%);
  margin: 35px;
`;

const FieldWrapper = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
`;

const FieldLabel = styled.label`
  font-size: 18px;
  margin-right: 10px;
`;

const FieldValue = styled.input`
  outline: none;
  border: 0.15em solid ${(props) => props.theme.primaryColor};
  border-radius: 15px;
  user-select: none;
  padding: 5px;
  background-color: white;
  height: 35px;
  width: 70%;
`;

const FieldError = styled.div`
  color: red;
  width: 40%;
  margin-top: 2px;
  margin-left: 55%;
`;

const AddButton = styled(Button)`
  width: fit-content;
  margin-left: 25px;
  margin-bottom: 15px;
`;

const SubmitButton = styled(Button)`
  height: 2.5em;
  width: 40%;
  background-color: ${(props) => props.theme[props.buttonColor]};
`;

const SubMenu = styled.div`
  font-size: 20px;
  font-weight: 400;
  padding: 5px 10px;
  margin: 25px;
  width: 100%;
  text-align: center;
  color: white;
  background-color: rgba(29, 31, 35, 1);
  border-radius: 10px;
  display: flex;
  flex-flow: row;
  border: 1px solid black;
  justify-content: space-evenly;
`;

const SubMenuItem = styled.div`
  margin: 5px;
  padding: 5px 10px;
  cursor: pointer;
`;

const HorizontalWrapper = styled.div`
  display: flex;
  flex-flow: row;
  overflow: auto;
`;

const MainHorizontalWrapper = styled(HorizontalWrapper)`
  height: 100%;
`;

const ToolWrapper = styled.div`
  flex-basis: 30%;
  display: flex;
  flex-flow: column;
  align-items: center;
`;

const InfoWrapper = styled.div`
  flex-basis: 70%;
  display: flex;
  margin-right: 25px;
  flex-flow: column;
  overflow: auto;
`;

const InfoWrapper2 = styled.div`
  flex-basis: 70%;
  display: flex;
  margin-right: 25px;
  flex-flow: column;
  align-items: center;
`;

const AccountsWrapper = styled.div`
  display: flex;
  flex-flow: column;
  overflow: auto;
  padding: 5px;
  padding-left: 25px;
  height: 70%;
`;

const StandhoudersWrapper = styled.div`
  display: flex;
  flex-flow: column;
  overflow: auto;
  padding: 5px;
  height: 70%;
`;

const StyledInput = styled.input`
  max-width: 20%;
  max-height: 25px;
  margin-bottom: 25px;
  outline: none;
`;

const StyledInput2 = styled.input`
  max-width: 20%;
  margin-bottom: 25px;
  margin-left: 25px;
  outline: none;
`;

const FormInput = styled(StyledInput)`
  max-width: 30%;
  height: 25px;
`;

const StandplaatsenWrapper = styled.div`
  display: flex;
  flex-flow: column;
  overflow: auto;
  padding: 5px;
  padding-right: 50px;
  width: fit-content;
  min-width: 20vw;
  height: 65vh;
`;

const Info = styled.div`
  margin-top: 15px;
  margin-left: 25px;
  margin-bottom: 15px;
  color: white;
  font-weight: bold;
`;

const SortButton = styled.div`
  background-color: rgba(29, 31, 35, 1);
  border-radius: 10px;
  color: white;
  margin-left: 10px;
  padding: 5px 10px;
  cursor: pointer;
  height: fit-content;
`;

const subPages = {
  currentMarkt: "Standhouders",
  standplaatsen: "Standplaatsen",
  accounts: "Accounts",
};

const AdminPage = () => {
  const [accounts, setAccounts] = useState([]);
  const [filteredAccounts, setFilteredAccounts] = useState([]);

  const [standhouders, setStandhouders] = useState([]);
  const [filteredStandhouders, setFilteredStandhouders] = useState([]);

  const [standplaatsen, setStandplaatsen] = useState([]);
  const [filteredStandplaatsen, setFilteredStandplaatsen] = useState([]);

  const [sortMethod, setSortMethod] = useState("date");

  const [addNew, setAddNew] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [info, setInfo] = useState("");

  const { AuthState } = useAuthState();
  const { token } = AuthState;

  const [currentPage, setCurrentPage] = useState(subPages.currentMarkt);
  const [selectedStandhouder, setSelectedStandhouder] = useState();

  // const [allMails, setAllMails] = useState([]);
  // const [currentMails, setCurrentMails] = useState(0);

  //const [intervalId, setIntervalId] = useState();

  const [searchValue, setSearchValue] = useState("");
  const buttons = [];
  Object.keys(subPages).forEach((key, index) => {
    buttons.push(
      <SubMenuItem
        key={index}
        onClick={() => {
          setSearchValue("");
          setCurrentPage(subPages[key]);
        }}
      >
        {subPages[key]}
      </SubMenuItem>
    );
  });

  // Search Standhouders
  useEffect(() => {
    if (searchValue && standhouders && currentPage === subPages.currentMarkt) {
      const lower = searchValue.toLowerCase();
      setFilteredStandhouders(
        standhouders
          .filter((standhouder) => {
            return (
              standhouder.StandhouderID === parseInt(lower) ||
              standhouder.Email.toLowerCase().includes(lower) ||
              standhouder.Naam.toLowerCase().includes(lower) ||
              standhouder.Voornaam.toLowerCase().includes(lower) ||
              standhouder.Straat.toLowerCase().includes(lower) ||
              standhouder.Gemeente.toLowerCase().includes(lower) ||
              standhouder.Standplaats.toLowerCase().includes(lower)
            );
          })
          .sort((a, b) => {
            if (sortMethod === "date") {
              return -a.Inschrijvingsnummer.localeCompare(
                b.Inschrijvingsnummer
              );
            } else {
              const A = parseInt(a.Standplaats);
              const B = parseInt(b.Standplaats);

              return A - B;
            }
          })
      );
    } else {
      setFilteredStandhouders(
        standhouders.sort((a, b) => {
          if (sortMethod === "date") {
            return -a.Inschrijvingsnummer.localeCompare(b.Inschrijvingsnummer);
          } else {
            const A = parseInt(a.Standplaats);
            const B = parseInt(b.Standplaats);

            return A - B;
          }
        })
      );
    }
  }, [searchValue, standhouders, currentPage, sortMethod]);

  // Search Standplaatsen
  useEffect(() => {
    if (
      searchValue &&
      standplaatsen &&
      currentPage === subPages.standplaatsen
    ) {
      const lower = searchValue.toLowerCase();
      setFilteredStandplaatsen(
        standplaatsen
          .filter((standplaats) => {
            return (
              standplaats.Straat.toLowerCase().includes(lower) ||
              standplaats.Standplaats.toLowerCase().includes(lower)
            );
          })
          .sort((a, b) => {
            return a.Standplaats - b.Standplaats;
          })
      );
    } else {
      setFilteredStandplaatsen(
        standplaatsen.sort((a, b) => {
          const A = parseInt(a.Standplaats);
          const B = parseInt(b.Standplaats);

          return A - B;
        })
      );
    }
  }, [searchValue, standplaatsen, currentPage]);

  // Search Accounts
  useEffect(() => {
    if (searchValue && accounts && currentPage === subPages.accounts) {
      const lower = searchValue.toLowerCase();
      setFilteredAccounts(
        accounts
          .filter((account) => {
            return (
              account.Naam.toLowerCase().includes(lower) ||
              account.Voornaam.toLowerCase().includes(lower) ||
              account.Straat.toLowerCase().includes(lower) ||
              account.Gemeente.toLowerCase().includes(lower) ||
              account.StandhouderID === parseInt(lower)
            );
          })
          .sort((a, b) => {
            return a.StandhouderID - b.StandhouderID;
          })
      );
    } else {
      setFilteredAccounts(
        accounts.sort((a, b) => {
          const A = parseInt(a.StandhouderID);
          const B = parseInt(b.StandhouderID);

          return A - B;
        })
      );
    }
  }, [searchValue, accounts, currentPage]);

  useEffect(() => {
    if (token && currentPage) {
      switch (currentPage) {
        case subPages.currentMarkt:
          GetAllStandhouders(token).then((result) => {
            if (result.data) {
              setStandhouders(result.data);
            }
          });
          break;
        case subPages.standplaatsen:
          GetAllStandplaatsen(token).then((result) => {
            if (result.data) {
              setStandplaatsen(result.data);
            }
          });
          break;
        case subPages.accounts:
          GetAllAccounts(token).then((result) => {
            if (result.data) {
              setAccounts(result.data);
            }
          });
          break;
        default:
      }
    }
  }, [token, currentPage]);

  // const sentNextMail = (emails) => {
  //   setCurrentMails((current) => {
  //     const email = emails[current];

  //     MessageLastYear(token, email);

  //     console.log("mail sent to " + email);

  //     return current + 1;
  //   });
  // };

  // useEffect(() => {
  //   if (currentMails >= 8) {
  //     window.clearInterval(intervalId);
  //   }
  // }, [intervalId, allMails, currentMails]);

  // const getAndSendMails = () => {
  //   GetAllStandhoudersVorigJaar(token).then((result) => {
  //     const { data } = result;

  //     const emails = data
  //       .filter((x) => {
  //         return (
  //           x.Email !== "rosemarierosematthysrose@outlook.com" &&
  //           x.Email !== "vw.eddy@skynet.be" &&
  //           x.Email !== "frankyvanderbiest2@live.be" &&
  //           x.Email !== "gonny.vanzundert@hotmail.com" &&
  //           x.Email !== "kittyvanhove@live.nl" &&
  //           x.Email !== "bertpenning@hotmail.com" &&
  //           x.Email !== "roger.de.backer3@telenet.be" &&
  //           x.Email !== "gospa@skynet.be" &&
  //           x.Email !== "ponceletschotte@gmail.com" &&
  //           x.Email !== "nadinedefoer@hotmail.com" &&
  //           x.Email !== "elspattyn@live.be" &&
  //           x.Email !== "stientje.desmet@skynet.be" &&
  //           x.Email !== "inge_jp@hotmail.com" &&
  //           x.Email !== "tarwekiempje@hotmail.com" &&
  //           x.Email !== "fvantielcke@laposte.net"
  //         );
  //       })
  //       .filter((x) => x.Email !== "0")
  //       .map((x) => x.Email);
  //     //const emails = [];
  //     emails.unshift("quentin.mussche@gmail.com");
  //     emails.unshift("quentin.mussche@prompto.com");
  //     emails.unshift("quentin.mussche@gmail.com");
  //     emails.unshift("quentin.mussche@prompto.com");
  //     emails.unshift("quentin.mussche@gmail.com");
  //     emails.unshift("quentin.mussche@prompto.com");

  //     console.log(emails.join(","));

  //     // const emails = [
  //     //   "quentin.mussche@gmail.com",
  //     //   "quentin.mussche@prompto.com",
  //     //   "quentin.mussche@gmail.com",
  //     //   "quentin.mussche@prompto.com",
  //     // ];

  //     setAllMails(emails);

  //     // setIntervalId(
  //     //   window.setInterval(() => {
  //     //     sentNextMail(emails);
  //     //   }, 4000)
  //     // );
  //   });
  // };

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      number: "",
    },
    validationSchema: SignupSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values, { resetForm }) => {
      if (submitted) {
        return;
      }
      setSubmitted(true);

      values.password = md5("Kaaifeesten2021");
      values.passwordConfirm = md5("Kaaifeesten2021");

      RegisterAccount(values)
        .then((result) => {
          setInfo("account toegevoegd");
          setAddNew(false);

          setAccounts((current) => {
            return [
              ...current,
              {
                Email: values.email,
                GSM: values.phoneNumber,
                Gemeente: values.city,
                Huisnummer: values.number,
                Land: values.country,
                Naam: values.lastName,
                Postcode: values.zipCode,
                Straat: values.street,
                Voornaam: values.firstName,
              },
            ];
          });
          resetForm();

          setSubmitted(false);

          setTimeout(() => {
            setInfo("");
          }, 5000);
        })
        .catch((e) => {
          const { data } = e.response;

          if (data.localizer) {
            setInfo(`account niet toegevoegd: ${localizer[data.localizer]}`);
          }

          setSubmitted(false);
        });
    },
  });

  let content;
  switch (currentPage) {
    case subPages.currentMarkt:
      content = (
        <MainHorizontalWrapper>
          <ToolWrapper>
            <FormInput
              placeholder="StandhouderID"
              id="standhouderID"
            ></FormInput>
            <FormInput placeholder="Standplaats" id="standplaats"></FormInput>
            <button
              type="submit"
              onClick={() => {
                const standhouderID =
                  document.getElementById("standhouderID").value;
                const plaats = document.getElementById("standplaats").value;

                const body = {
                  StandhouderID: standhouderID,
                  Standplaats: [plaats],
                };

                AddToMarkt(body, token);
                setTimeout(() => {
                  document.getElementById("standhouderID").value = "";
                  document.getElementById("standplaats").value = "";
                  GetAllStandhouders(token).then((result) => {
                    if (result.data) {
                      setStandhouders(result.data);
                    }
                  });
                }, 500);
              }}
            >
              Voeg toe
            </button>
            <StandhouderEditCard
              standhouder={selectedStandhouder}
              standhouders={standhouders}
              onRefresh={(standhouder) => {
                setTimeout(() => {
                  GetAllStandhouders(token).then((result) => {
                    if (result.data) {
                      setStandhouders(result.data);
                      setSelectedStandhouder(
                        result.data.filter(
                          (x) => x.StandhouderID === standhouder.StandhouderID
                        )[0]
                      );
                    }
                  });
                }, 500);
              }}
            />
          </ToolWrapper>
          <InfoWrapper>
            <HorizontalWrapper>
              <StyledInput
                placeholder="Zoek"
                onChange={(e) => {
                  setSearchValue(e.target.value);
                }}
              ></StyledInput>
              Totaal: {standhouders.length}
              <SortButton
                onClick={() => {
                  setSortMethod("standplaats");
                  setStandhouders((current) => [...current]);
                }}
              >
                Sorteer op standplaats
              </SortButton>
              <SortButton
                onClick={() => {
                  setSortMethod("date");
                  setStandhouders((current) => [...current]);
                }}
              >
                Sorteer op datum
              </SortButton>
            </HorizontalWrapper>
            <StandhoudersWrapper>
              {filteredStandhouders.map((standhouder, index) => {
                return (
                  <StandhouderCard
                    key={`Standhouder${standhouder.StandhouderID}-${index}}`}
                    standhouder={standhouder}
                    standhouders={standhouders}
                    onClick={() => {
                      setSelectedStandhouder(standhouder);
                    }}
                  />
                );
              })}
            </StandhoudersWrapper>
          </InfoWrapper>
        </MainHorizontalWrapper>
      );
      break;
    case subPages.standplaatsen:
      content = (
        <InfoWrapper2>
          <StyledInput
            placeholder="Zoek"
            onChange={(e) => {
              setSearchValue(e.target.value);
            }}
          ></StyledInput>
          Totaal: {standplaatsen.length}
          <StandplaatsenWrapper>
            {filteredStandplaatsen.map((standplaats, index) => {
              return <StandplaatsCard key={index} standplaats={standplaats} />;
            })}
          </StandplaatsenWrapper>
        </InfoWrapper2>
      );
      break;
    case subPages.accounts:
      content = (
        <>
          <StyledInput2
            placeholder="Zoek"
            onChange={(e) => {
              setSearchValue(e.target.value);
            }}
          ></StyledInput2>
          {/* <button
            onClick={() => {
              getAndSendMails();
            }}
          >
            Get mails
          </button> */}
          {/* <div>{`${currentMails}/${allMails.length}`}</div> */}
          {!addNew && (
            <AddButton
              onClick={() => {
                setAddNew(true);
              }}
              text={"Voeg toe"}
              enabled={true}
            />
          )}
          {addNew && (
            <StyledForm onSubmit={formik.handleSubmit}>
              <FieldWrapper>
                <FieldLabel htmlFor="firstName">
                  {localizer.firstName}
                </FieldLabel>
                <FieldValue
                  id="firstName"
                  name="firstName"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.firstName}
                />
                <FieldError>{formik.errors.firstName}</FieldError>
              </FieldWrapper>
              <FieldWrapper>
                <FieldLabel htmlFor="lastName">{localizer.lastName}</FieldLabel>
                <FieldValue
                  id="lastName"
                  name="lastName"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.lastName}
                />
                <FieldError>{formik.errors.lastName}</FieldError>
              </FieldWrapper>
              <FieldWrapper>
                <FieldLabel htmlFor="street">{localizer.street}</FieldLabel>
                <FieldValue
                  id="street"
                  name="street"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.street}
                />
                <FieldError>{formik.errors.street}</FieldError>
              </FieldWrapper>
              <FieldWrapper>
                <FieldLabel htmlFor="number">{localizer.number}</FieldLabel>
                <FieldValue
                  id="number"
                  name="number"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.number}
                />
                <FieldError>{formik.errors.number}</FieldError>
              </FieldWrapper>
              <FieldWrapper>
                <FieldLabel htmlFor="zipCode">{localizer.zipCode}</FieldLabel>
                <FieldValue
                  id="zipCode"
                  name="zipCode"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.zipCode}
                />
                <FieldError>{formik.errors.zipCode}</FieldError>
              </FieldWrapper>
              <FieldWrapper>
                <FieldLabel htmlFor="city">{localizer.city}</FieldLabel>
                <FieldValue
                  id="city"
                  name="city"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.city}
                />
                <FieldError>{formik.errors.city}</FieldError>
              </FieldWrapper>
              <FieldWrapper>
                <FieldLabel htmlFor="country">{localizer.country}</FieldLabel>
                <FieldValue
                  id="country"
                  name="country"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.country}
                />
                <FieldError>{formik.errors.country}</FieldError>
              </FieldWrapper>
              <FieldWrapper>
                <FieldLabel htmlFor="phoneNumber">
                  {localizer.phoneNumber}
                </FieldLabel>
                <FieldValue
                  id="phoneNumber"
                  name="phoneNumber"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.phoneNumber}
                />
                <FieldError>{formik.errors.phoneNumber}</FieldError>
              </FieldWrapper>
              <FieldWrapper>
                <FieldLabel htmlFor="email">
                  {capitalize(localizer.emailAddress)}
                </FieldLabel>
                <FieldValue
                  id="email"
                  name="email"
                  type="email"
                  onChange={(e) => {
                    formik.handleChange(e);
                  }}
                  value={formik.values.email}
                />
                <FieldError>{formik.errors.email}</FieldError>
              </FieldWrapper>
              <FieldWrapper>
                <FieldLabel htmlFor="emailConfirm">{`${localizer.confirm} ${localizer.emailAddress}`}</FieldLabel>
                <FieldValue
                  id="emailConfirm"
                  name="emailConfirm"
                  type="email"
                  onChange={formik.handleChange}
                  value={formik.values.emailConfirm}
                />
                <FieldError>{formik.errors.emailConfirm}</FieldError>
              </FieldWrapper>
              <SubmitButton type="submit" text={"bevestig"} enabled={true} />
            </StyledForm>
          )}
          <Info>{info}</Info>
          <AccountsWrapper>
            {filteredAccounts.map((account, index) => {
              return <AccountCard key={account.StandhouderID} user={account} />;
            })}
          </AccountsWrapper>
        </>
      );
      break;
    default:
  }

  return (
    <>
      <SubMenu>{buttons}</SubMenu>
      {content}
    </>
  );
};

export default AdminPage;
