import React from "react";

// Components
import { motion } from "framer-motion";
import { Tooltip } from "react-tippy";

// Helpers
import { useAuthState } from "./stores/AuthStore";
import localizer from "localization/localizer";

import { useNavigate, Link } from "react-router-dom";

// Styling
import styled from "styled-components";

const Wrapper = styled(motion.div)`
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  z-index: 500;
  display: flex;
  flex-flow: column;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const MenuBar = styled(motion.div)`
  width: 80%;
  height: 100%;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  padding: 15px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const MenuItem = styled(Link)`
  text-decoration: none;
  font-size: 1.15rem;
  color: ${(props) =>
    props.active ? `rgba(44, 74, 118, 0.75)` : props.theme.primary900};
  border-bottom: ${({ active }) =>
    active ? `1px solid rgba(44, 74, 118, 0.75)` : "none"};
  user-select: none;
  cursor: pointer;
  margin-right: 100px;
  padding-bottom: 3px;

  :hover {
    color: rgba(44, 74, 118, 0.75);
    border-bottom: 1px solid rgba(44, 74, 118, 0.75);
  }

  @media (max-width: 768px) {
    margin-right: 20px;
  }
`;

const LanguageWrapper = styled(motion.div)`
  top: 5px;
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  z-index: 500;
`;

const Flag = styled.img`
  margin-right: 5px;
  cursor: pointer;
`;

const Tip = styled.div`
  background-color: white;
  padding: 2px;
`;

// const InfoMessage = styled.div`
//   font-size: 1.4rem;
//   margin: auto;
//   color: red;
//   white-space: pre;
//   text-shadow: 1px 1px #000000;
// `;

const ProgramButton = styled(MenuItem)``;
const MarketButton = styled(MenuItem)``;
const InfoButton = styled(MenuItem)``;
// const GalleryButton = styled(MenuItem)``;
const LoginButton = styled(MenuItem)`
  margin-left: auto;
`;
const LogoutButton = styled.div`
  text-decoration: none;
  font-size: 1.15rem;
  color: ${(props) =>
    props.active ? `rgba(44, 74, 118, 0.75)` : props.theme.primary900};
  border-bottom: ${({ active }) =>
    active ? `1px solid rgba(44, 74, 118, 0.75)` : "none"};
  user-select: none;
  cursor: pointer;
  margin-right: 100px;
  padding-bottom: 3px;

  :hover {
    color: rgba(44, 74, 118, 0.75);
    border-bottom: 1px solid rgba(44, 74, 118, 0.75);
  }
`;
const AccountButton = styled(MenuItem)`
  margin-left: auto;
`;

const Logo = styled.img`
  margin-right: 100px;
  height: 45px;

  @media (max-width: 768px) {
    display: none;
  }
`;

const NavigationMenu = ({
  onHomeClick,
  onProgramClick,
  onMarketClick,
  onGalleryClick,
  onInfoClick,
  onLoginClick,
  onLogoutClick,
  onAccountClick,
  onChangeLanguage,
}) => {
  const { AuthState, AuthStateDispatch } = useAuthState();
  const { authenticated } = AuthState;
  const navigate = useNavigate();
  return (
    <Wrapper
      initial={{ height: 0, opacity: 0.0 }}
      animate={{ height: "50px", opacity: 1.0 }}
      exit={{ height: 0, opacity: 0 }}
      transition={{ duration: 0.5, type: "tween", ease: "easeIn" }}
    >
      <MenuBar>
        <Logo src="Logo.png" alt="logo" />

        <InfoButton to={"info"}>{"Info"}</InfoButton>
        <MarketButton to={"market"}>{localizer.garageSale}</MarketButton>
        <ProgramButton to={"program"}>{localizer.program}</ProgramButton>

        {authenticated ? (
          <>
            <AccountButton to={"account"}>{localizer.account}</AccountButton>
            <LogoutButton
              onClick={() => {
                AuthStateDispatch({
                  type: "logout",
                });
                navigate("/login");
              }}
            >
              {localizer.logout}
            </LogoutButton>
          </>
        ) : (
          <LoginButton to={"login"}>{localizer.login}</LoginButton>
        )}
        <LanguageWrapper>
          <Tooltip html={<Tip>Nederlands</Tip>} position="top">
            <Flag
              src="resources/netherlands.png"
              height="16"
              onClick={() => {
                console.log("onchange to nl");
                onChangeLanguage("nl");
              }}
            />
          </Tooltip>
          <Tooltip html={<Tip>English</Tip>} position="top">
            <Flag
              src="resources/uk.png"
              height="16"
              onClick={() => {
                onChangeLanguage("en");
              }}
            />
          </Tooltip>
        </LanguageWrapper>
      </MenuBar>
    </Wrapper>
  );
};

export default NavigationMenu;
