import React from "react";

// Components
import CardSlider from "./CardSlider";

import Harvesters from "resources/vrijdag/HARVESTERS.jpg";
import USE from "resources/vrijdag/U.S.E..jpg";
import UP_THE_IRONS from "resources/vrijdag/UP_THE_IRONS.jpg";

import { useNavigate } from "react-router-dom";

import styled from "styled-components";

const Title = styled.h3`
  text-align: center;
`;

const FridayPage = () => {
  const navigate = useNavigate();

  const fridayCards = [
    {
      title: "20u - Harvesters",
      image: Harvesters,
    },
    {
      title: "U.S.E.",
      image: USE,
    },
    {
      title: "Up The Irons",
      image: UP_THE_IRONS,
    },
  ];

  return (
    <>
      <Title>Vrijdag 29/09</Title>
      <CardSlider
        key="friday"
        cards={fridayCards}
        onBackToOverview={() => {
          navigate("..");
        }}
        onRedirect={() => {}}
      />
    </>
  );
};

export default FridayPage;
