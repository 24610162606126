import LocalizedStrings from "react-localization";

import enStrings from "./en/en-US-strings.json";
import enErrors from "./en/en-US-errors.json";
import nlStrings from "./nl/nl-NL-strings.json";
import nlErrors from "./nl/nl-NL-errors.json";

const languagesFiles = {
  nl: { ...nlStrings, ...nlErrors },
  en: { ...enStrings, ...enErrors },
};

const strings = new LocalizedStrings(languagesFiles);

export const capitalize = (string) =>
  string && `${string.charAt(0).toUpperCase()}${string.slice(1)}`;

export const setLanguage = (lang) => {
  strings.setLanguage(lang);
};

export const getLanguage = () => {
  return strings.getLanguage();
};

export const defaultLocale = "nl";

export default strings;
