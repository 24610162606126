import React from "react";

// Components
import { motion } from "framer-motion";

// Styling
import styled from "styled-components";

const Wrapper = styled(motion.div)`
  height: 100%;
  padding: 30px;
  box-sizing: border-box;
  overflow: auto;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
`;

const Page = (props) => {
  return (
    <Wrapper
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
      exit={{ opacity: 0 }}
    >
      {props.children}
    </Wrapper>
  );
};

export default Page;
