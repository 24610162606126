import React, { useState, useEffect } from "react";

// Components
import NavigationMenu from "./NavigationMenu";
import { AnimatePresence } from "framer-motion";
import { setLanguage } from "localization/localizer";
// Helpers
import Cookies from "universal-cookie";
import { useAuthState } from "./stores/AuthStore";
import { GetAccountInfo, GetStandplaatsenLastYearForID } from "./api/calls";
import { isPersonFromNeighbourhood } from "helpers";

// Helpers
import { Outlet, useNavigate, useLocation } from "react-router-dom";

const App = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [init, setInit] = useState(false);
  const { AuthState, AuthStateDispatch } = useAuthState();
  const cookies = new Cookies();

  if (cookies.get("authenticated") && !AuthState.authenticated) {
    const email = cookies.get("email");
    const token = cookies.get("token");

    if (email) {
      GetAccountInfo(email, token).then((result) => {
        GetStandplaatsenLastYearForID(result.data[0].StandhouderID, token).then(
          (res) => {
            const { data } = res;

            AuthStateDispatch({
              type: "authenticate",
              payload: {
                user: {
                  ...result.data[0],
                  placesLastYear: data,
                  isPersonFromNeighbourhood: isPersonFromNeighbourhood(
                    result.data[0]
                  ),
                },
                authenticated: true,
                token: token,
              },
            });
          }
        );
      });
    }
  }

  useEffect(() => {
    if (!init) {
      console.log("set dutch");
      setLanguage("nl");
      setInit(true);
    }
  }, [init]);

  return (
    <AnimatePresence>
      {location.pathname !== "/" && (
        <NavigationMenu
          onChangeLanguage={(lang) => {
            setLanguage(lang);
            navigate(location.pathname);
          }}
        />
      )}
      <Outlet />
    </AnimatePresence>
  );
};

export default App;
