import React, { useState, useEffect } from "react";

// Helpers
import { useAuthState } from "stores/AuthStore";

import { RemoveFromMarkt } from "api/calls";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Styling
import styled from "styled-components";

const MainWrapper = styled.div`
  margin: 25px;
  display: flex;
  flex-flow: column;
`;

const Header = styled.div`
  display: flex;
  flex-flow: row;
  color: white;
  background-color: rgba(29, 31, 35, 1);
  border-radius: 10px;
`;

const HeaderItem = styled.div`
  margin: 5px;
  padding: 5px 10px;
  cursor: pointer;
`;

const Message = styled.div`
  margin-top: 25px;
`;

const Card = styled.div`
  background: white;
  display: flex;
  flex-flow: row;
  border: 1px solid black;
  padding: 10px 15px;
  margin: 5px;
  justify-content: space-around;
  align-items: center;
`;

const Plaats = styled.div`
  font-size: 20px;
  align-items: center;
  text-align: center;
`;

const DeleteButton = styled.div`
  padding: 10px;
  color: red;
  cursor: pointer;
`;

const StandhouderEditCard = ({ standhouder, standhouders, onRefresh }) => {
  const { AuthState } = useAuthState();
  const { token } = AuthState;

  const [standplaatsen, setStandplaatsen] = useState();

  useEffect(() => {
    if (standhouder && standhouders) {
      setStandplaatsen(
        standhouders
          .filter((x) => x.StandhouderID === standhouder.StandhouderID)
          .map((x) => x.Standplaats)
      );
    }
  }, [standhouder, standhouders]);

  const onDeletePlaats = (plaats) => {
    const body = {
      StandhouderID: standhouder.StandhouderID,
      Standplaats: plaats,
    };

    RemoveFromMarkt(body, token);
    onRefresh(standhouder);
  };

  if (!standhouder) {
    return <Message>Selecteer een standhouder om aan te passen</Message>;
  }

  return (
    <MainWrapper>
      <Header>
        <HeaderItem> {standhouder.StandhouderID}</HeaderItem>
        <HeaderItem> {standhouder.Voornaam}</HeaderItem>
        <HeaderItem> {standhouder.Naam}</HeaderItem>
      </Header>
      {standplaatsen &&
        standplaatsen.map((x) => {
          return (
            <Card key={x}>
              <Plaats>{x}</Plaats>
              <DeleteButton
                onClick={() => {
                  onDeletePlaats(x);
                }}
              >
                <FontAwesomeIcon icon={["fas", "trash-alt"]} />
              </DeleteButton>
            </Card>
          );
        })}
    </MainWrapper>
  );
};

export default StandhouderEditCard;
