import React from "react";

// Components
import Page from "../Page";
import AccountInfoForm from "./AccountInfoForm";
import CurrentPlaces from "./CurrentPlaces";

// Helpers
import { useAuthState } from "stores/AuthStore";
//import localizer from "localization/localizer";

// Styling
import styled from "styled-components";
//import PlacesLastYear from "./PlacesLastYear";
import { motion } from "framer-motion";

const DashBoard = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
`;

const Container = styled(motion.div)`
  width: 50%;
`;

// Account info
// Reset password
// Huidige standplaatsen
// Standplaatsen vorige edities 2 containers

const AccountPage = () => {
  const { AuthState } = useAuthState();
  const { user } = AuthState;

  console.log(user);

  return (
    <Page>
      <DashBoard>
        <Container
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1, delay: 0 }}
        >
          <CurrentPlaces />
        </Container>
        <Container
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1, delay: 0 }}
        >
          <AccountInfoForm user={user} />
        </Container>
      </DashBoard>
    </Page>
  );
};

export default AccountPage;
