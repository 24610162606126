import React, { useState } from "react";

// Components
import Button from "components/Button";

// helpers
import { useAuthState } from "stores/AuthStore";
import { UpdateAccount } from "api/calls";
import localizer from "localization/localizer";

// Styling
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

const FormWrapper = styled.div`
  display: flex;
  flex-flow: column;
  width: 90%;
  height: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 3em;
  margin-bottom: 3em;
  padding-top: 1em;
  overflow-y: auto;
`;

const Title = styled.div`
  font-size: 30px;
  font-weight: 400;
  padding: 10px 25px;
  margin: 10px;
  width: 95%;
  text-align: center;
  border: none;
  color: white;
  background: rgba(44, 74, 118, 0.75);
  border-radius: 3px;
`;

const FieldWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: row;
  margin-bottom: 25px;
  width: 100%;
`;
const FieldLabel = styled.div`
  font-size: 16px;
  width: 30%;
`;

const FieldValue = styled.input`
  outline: none;
  border: 0.15em solid ${(props) => props.theme.primaryColor};
  border-radius: 15px;
  user-select: none;
  padding: 5px;
  background-color: white;
  width: 60%;
  height: 35px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: row;
  width: 100%;
  margin-top: auto;
  margin-bottom: 20px;
  padding-left: 25px;
  padding-right: 25px;
`;

const CancelButton = styled(Button)`
  width: 20%;
`;

const SaveButton = styled(Button)`
  width: 20%;
  margin-left: auto;
`;

const AccountInfoForm = ({ user }) => {
  const { AuthState, AuthStateDispatch } = useAuthState();
  const { token } = AuthState;

  const [updatedUser, setUpdatedUser] = useState(user);
  const [hasChanges, setHasChanges] = useState(false);
  const onChange = (newUser) => {
    if (!checkIfChanges(user, newUser)) {
      setHasChanges(true);
    } else {
      setHasChanges(false);
    }

    setUpdatedUser(newUser);
  };

  const checkIfChanges = (a, b) => {
    const keys1 = Object.keys(a);
    const keys2 = Object.keys(b);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (const key of keys1) {
      if (a[key] !== b[key]) {
        return false;
      }
    }

    return true;
  };

  const resetFields = () => {
    document.getElementById("voornaam").value = user.Voornaam;
    document.getElementById("naam").value = user.Naam;
    document.getElementById("straat").value = user.Straat;
    document.getElementById("huisNummer").value = user.Huisnummer;
    document.getElementById("postcode").value = user.Postcode;
    document.getElementById("gemeente").value = user.Gemeente;
    document.getElementById("land").value = user.Land;
    document.getElementById("gsm").value = user.GSM;

    setHasChanges(false);
  };

  const onSaveChanges = () => {
    UpdateAccount(updatedUser.StandhouderID, updatedUser, token).then(
      (result) => {
        if (result.data) {
          AuthStateDispatch({
            type: "update",
            payload: {
              user: {
                ...updatedUser,
              },
            },
          });
        }
      }
    );
  };

  return (
    <Wrapper>
      <Title>{`${localizer.account} ${localizer.info}`}</Title>
      <FormWrapper>
        <FieldWrapper>
          <FieldLabel>{localizer.firstName}</FieldLabel>
          <FieldValue
            id="voornaam"
            type="text"
            defaultValue={user.Voornaam}
            onChange={(event) => {
              onChange({ ...updatedUser, Voornaam: event.target.value });
            }}
          />
        </FieldWrapper>
        <FieldWrapper>
          <FieldLabel>{localizer.lastName}</FieldLabel>
          <FieldValue
            id="naam"
            type="text"
            defaultValue={user.Naam}
            onChange={(event) => {
              onChange({ ...updatedUser, Naam: event.target.value });
            }}
          />
        </FieldWrapper>
        <FieldWrapper>
          <FieldLabel>{localizer.street}</FieldLabel>
          <FieldValue
            id="straat"
            type="text"
            defaultValue={user.Straat}
            onChange={(event) => {
              onChange({ ...updatedUser, Straat: event.target.value });
            }}
          />
        </FieldWrapper>
        <FieldWrapper>
          <FieldLabel>{localizer.number}</FieldLabel>
          <FieldValue
            id="huisNummer"
            type="text"
            defaultValue={user.Huisnummer}
            onChange={(event) => {
              onChange({ ...updatedUser, Huisnummer: event.target.value });
            }}
          />
        </FieldWrapper>
        <FieldWrapper>
          <FieldLabel>{localizer.zipCode}</FieldLabel>
          <FieldValue
            id="postcode"
            type="text"
            defaultValue={user.Postcode}
            onChange={(event) => {
              onChange({ ...updatedUser, Postcode: event.target.value });
            }}
          />
        </FieldWrapper>
        <FieldWrapper>
          <FieldLabel>{localizer.city}</FieldLabel>
          <FieldValue
            id="gemeente"
            type="text"
            defaultValue={user.Gemeente}
            onChange={(event) => {
              onChange({ ...updatedUser, Gemeente: event.target.value });
            }}
          />
        </FieldWrapper>
        <FieldWrapper>
          <FieldLabel>{localizer.country}</FieldLabel>
          <FieldValue
            id="land"
            type="text"
            defaultValue={user.Land}
            onChange={(event) => {
              onChange({ ...updatedUser, Land: event.target.value });
            }}
          />
        </FieldWrapper>
        <FieldWrapper>
          <FieldLabel>{localizer.phoneNumber}</FieldLabel>
          <FieldValue
            id="gsm"
            type="text"
            defaultValue={user.GSM}
            onChange={(event) => {
              onChange({ ...updatedUser, GSM: event.target.value });
            }}
          />
        </FieldWrapper>
        <ButtonWrapper>
          <CancelButton
            text="Cancel"
            enabled={hasChanges}
            onClick={resetFields}
          />
          <SaveButton
            text="Save"
            enabled={hasChanges}
            onClick={onSaveChanges}
          />
        </ButtonWrapper>
      </FormWrapper>
    </Wrapper>
  );
};

export default AccountInfoForm;
