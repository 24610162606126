import React, { useRef, useEffect } from "react";

// Components
import localizer from "localization/localizer";

// Styling
import styled from "styled-components";
import { motion } from "framer-motion";

const MainWrapper = styled.div`
  height: 90%;
  display: flex;
  white-space: nowrap;
  justify-content: ${(props) =>
    props.needToCenter ? "center" : "flex - start"};

  @media (max-width: 768px) {
    flex-flow: column;
    align-items: center;
    row-gap: 10px;
    overflow: scroll;
    height: 80%;
  }
`;

const DayWrapper = styled.div`
  min-width: 20%;
  height: 85%;
  margin: 15px;

  &:last-child {
    padding-right: 35px;

    @media (max-width: 768px) {
      padding-right: 0;
    }
  }

  @media (max-width: 768px) {
    width: 90%;
    height: 100%;
    min-height: 200px;
    margin: 0;
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  top: ${(props) => (props.twoLines ? "-9%" : "-5%")};
  width: 100%;
  height: 100%;
  margin: 10px;
  overflow: hidden;
  box-shadow: 12px 12px 5px 0px rgba(0, 0, 0, 0.2);
  cursor: ${(props) => (props.isOverview ? "pointer" : "default")};
  border-radius: 5px;

  @media (max-width: 768px) {
    margin: 0;
    top: ${(props) => (props.twoLines ? "-20%" : "-5%")};
  }
`;

const ImagePreview = styled(motion.img)`
  position: absolute;
  top: -100%;
  bottom: -100%;
  left: -100%;
  right: -100%;
  margin: auto;
  height: 100%;
  max-width: none;
  transition: width 1s ease, height 1s ease;

  @media (max-width: 768px) {
    width: 100%;
    height: auto;
    bottom: ${({ down }) => (down ? `-${down}%` : "-100%")};
  }
`;

const InfoWrapper = styled.div`
  position: relative;
  top: 90%;
  left: 10px;
  width: 100%;
  margin: auto;
  padding: 5px;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.5);
  user-select: none;
  cursor: ${(props) => (props.isOverview ? "pointer" : "default")};
  display: flex;
  flex-flow: column;
  align-items: center;

  @media (max-width: 768px) {
    left: 0;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    top: ${(props) => (props.twoLines ? "80%" : "95%")};
  }
`;

const Day = styled.div`
  text-align: center;
  font-size: 1.85rem;
  color: white;
  margin-bottom: 5px;
  white-space: pre;
`;

const FootNote = styled.div`
  position: relative;
  margin: 0;
  margin-left: auto;
  margin-right: auto;
  user-select: none;
  text-align: center;
  cursor: pointer;
  z-index: 40;
  margin-bottom: ${({ move }) => (move ? "40px" : "0px")};
`;

const CardSlider = ({
  cards,
  isOverview,
  onBackToOverview,
  onRedirect,
  twoLines = false,
}) => {
  const wrapperRef = useRef();

  useEffect(() => {
    document.addEventListener(
      "mousewheel",
      function (e) {
        if (wrapperRef.current) {
          wrapperRef.current.scrollLeft -= e.wheelDelta;
          e.preventDefault();
        }
      },
      false
    );
  }, []);

  return (
    <>
      {!isOverview && (
        <FootNote onClick={onBackToOverview} move={cards[0].twoLines}>
          {localizer.backToOverview}
        </FootNote>
      )}
      <MainWrapper needToCenter={cards.length <= 4} ref={wrapperRef}>
        {cards.map((card) => {
          return (
            <DayWrapper
              key={`card${card.title}`}
              onClick={() => {
                onRedirect(card.redirect);
              }}
            >
              <InfoWrapper isOverview={isOverview} twoLines={card.twoLines}>
                <Day>{card.title}</Day>
              </InfoWrapper>
              <ImageWrapper isOverview={isOverview} twoLines={card.twoLines}>
                <ImagePreview
                  whileHover={{ scale: 1.1 }}
                  transition={{ duration: 1.5 }}
                  src={card.image}
                  down={card.down}
                />
              </ImageWrapper>
            </DayWrapper>
          );
        })}
      </MainWrapper>
    </>
  );
};

export default CardSlider;
