import React, { useEffect, useState } from "react";

// Components
import Page from "./Page";
import Button from "components/Button";

// Helpers
import { useAuthState } from "../stores/AuthStore";
import { Login, GetStandplaatsenLastYearForID } from "../api/calls";
import { motion } from "framer-motion";
import localizer from "localization/localizer";
import md5 from "md5";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { isPersonFromNeighbourhood } from "helpers";

// Styling
import styled from "styled-components";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 30%,
    rgba(0, 0, 0, 0) 100%
  );
  border-radius: 3px;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  flex-flow: row;
  overflow: auto;
`;

const ScenePreviewWrapper = styled(motion.div)`
  position: relative;
  height: 100%;
  flex-basis: 50%;
  overflow: hidden;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;

  @media (max-width: 768px) {
    display: none;
  }
`;

const ScenePreview = styled.img`
  position: absolute;
  top: -100%;
  bottom: -100%;
  left: -100%;
  right: -100%;
  margin: auto;

  transition: width 1s ease, height 1s ease;
  max-width: none;

  @media (max-width: 768px) {
    display: none;
  }
`;

const Title = styled.div`
  margin-top: 2.5em;
  margin-bottom: 1em;
  font-weight: bold;
  font-size: 3rem;
`;

const LoginWrapper = styled.div`
  display: flex;
  flex-flow: column;
  flex-basis: 50%;
  height: 100%;
  align-items: center;

  @media (max-width: 768px) {
    flex-basis: 100%;
  }
`;

const FormWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const InputWrapper = styled.div`
  outline: none;
  border: 0.15em solid ${(props) => props.theme.primaryColor};
  border-radius: 15px;
  user-select: none;
  height: 3.5em;
  width: 60%;
  display: flex;
  padding: 5px;
  align-items: center;
  background-color: white;
  margin-top: 20px;

  @media (max-width: 768px) {
    width: 90%;
  }
`;

const Input = styled.input`
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 22px;
`;

const Icon = styled(FontAwesomeIcon)`
  opacity: 0.5;
  margin-left: 0.25em;
  margin-right: 0.25em;
  font-size: 25px;
`;

const ShowPasswordIcon = styled(Icon)`
  cursor: pointer;
  font-size: 25px;
`;

const Warning = styled.div`
  color: red;
  width: 60%;
  margin-top: 5px;
`;

const LoginButton = styled(Button)`
  height: 3.5em;
  width: 60%;
`;

const NoAccountMessage = styled.div`
  margin-top: 15px;
  text-align: center;
`;

const SingupLink = styled.a`
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
`;

const LoginPage = () => {
  const { AuthStateDispatch } = useAuthState();

  const [emailInput, setEmailInput] = useState();
  const [passInput, setPassInput] = useState();

  const [passwordWarning, setPasswordWarning] = useState();

  const [loginEnabled, setLoginEnabled] = useState();

  const [hidePassword, setHidePassword] = useState(true);

  const navigate = useNavigate();

  const tryLogin = () => {
    Login(emailInput, passInput)
      .then((result) => {
        const email = result.data[0].Email;
        const paswoord = result.data[0].Paswoord;

        if (
          email === "AdminDM" &&
          paswoord === "f16e577d397f0cef82a13981d04e713c"
        ) {
          AuthStateDispatch({
            type: "authenticate",
            payload: {
              user: {
                ...result.data[0],
              },
              authenticated: true,
              admin: true,
              token: result.data.token,
            },
          });

          navigate("/admin");
        } else {
          GetStandplaatsenLastYearForID(
            result.data[0].StandhouderID,
            result.data.token
          ).then((res) => {
            const { data } = res;

            AuthStateDispatch({
              type: "authenticate",
              payload: {
                user: {
                  ...result.data[0],
                  placesLastYear: data,
                  isPersonFromNeighbourhood: isPersonFromNeighbourhood(
                    result.data[0]
                  ),
                },
                authenticated: true,
                admin: false,
                token: result.data.token,
              },
            });

            navigate("/account");
          });
        }
      })
      .catch((e) => {
        if (e.response) {
          if (e.response.status === 501) {
            setPasswordWarning("Account geblokkeerd, bel naar 0476 46 30 92");
          } else {
            setPasswordWarning(localizer.genericError);
          }
        }
      });
  };

  useEffect(() => {
    if (emailInput && passInput) {
      setLoginEnabled(true);
    } else {
      setLoginEnabled(false);
    }
  }, [emailInput, passInput]);

  const onChangeEmail = () => {
    const email = document.getElementById("emailInput").value;
    if (email !== "") {
      setEmailInput(email);
    } else {
      setEmailInput();
    }
  };

  const onChangePass = () => {
    const pass = document.getElementById("passwordInput").value;
    if (pass !== "") {
      setPassInput(md5(pass));
      setPasswordWarning();
    } else {
      setPassInput();
      setPasswordWarning(localizer.fillInPassword);
    }
  };

  return (
    <Page>
      <Wrapper>
        <ScenePreviewWrapper>
          <ScenePreview src="resources/loginscreen.jpg" height="100%" />
        </ScenePreviewWrapper>
        <LoginWrapper>
          <Title>{localizer.login}</Title>
          <FormWrapper>
            <InputWrapper>
              <Icon icon={["far", "envelope"]} size="1x" />
              <Input
                id="emailInput"
                onChange={onChangeEmail}
                placeholder={localizer.emailExample}
              />
            </InputWrapper>
            <InputWrapper>
              <Icon icon={["fas", "lock"]} size="1x" />
              <Input
                id="passwordInput"
                type={hidePassword ? "password" : "text"}
                onChange={onChangePass}
                placeholder={localizer.password}
              />
              <ShowPasswordIcon
                onClick={() => {
                  setHidePassword((current) => !current);
                }}
                icon={["far", hidePassword ? "eye" : "eye-slash"]}
                size="2x"
              />
            </InputWrapper>
            <Warning>{passwordWarning}</Warning>
            <LoginButton
              onClick={() => {
                if (loginEnabled) {
                  tryLogin();
                }
              }}
              enabled={loginEnabled}
              text={localizer.login}
            />
            <NoAccountMessage>
              {`${localizer.noAccountYet} ${localizer.click} `}
              <SingupLink
                onClick={() => {
                  navigate("/signup");
                }}
              >
                {`${localizer.here}`}
              </SingupLink>
              {` ${localizer.toCreateOne}`}
            </NoAccountMessage>
            <NoAccountMessage>
              {`${localizer.forgotPassword} ${localizer.click} `}
              <SingupLink
                onClick={() => {
                  navigate("/reset");
                }}
              >
                {`${localizer.here}`}
              </SingupLink>
              {` ${localizer.toResetIt}`}
            </NoAccountMessage>
          </FormWrapper>
        </LoginWrapper>
      </Wrapper>
    </Page>
  );
};

export default LoginPage;
